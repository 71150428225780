import { ScaledIllustration } from "components/theme"
import RawNotFoundImage from "./img/404.svg?inline"
import RawAccessDeniedImage from "./img/access_denied.svg?inline"
import RawMomChildImage from "./img/mom_child.svg?inline"
import RawUnsupportedBrowserImage from "./img/unsupported_browser.svg?inline"

export const NotFoundImage = ScaledIllustration(RawNotFoundImage)
export const AccessDeniedImage = ScaledIllustration(RawAccessDeniedImage)
export const MomChildImage = ScaledIllustration(RawMomChildImage)
export const UnsupportedBrowserImage = ScaledIllustration(
  RawUnsupportedBrowserImage
)
