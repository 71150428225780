import React, { ReactNode } from "react"
import styled from "@emotion/styled"
import {
  Typography,
  Type,
  FontWeight,
} from "@intouchhealth/ui-components/typography"
import { indent, focusVisible } from "@intouchhealth/ui-components/theme"
import { Aqua, Blue } from "@intouchhealth/ui-components/colors"
import { css } from "@emotion/react"

type HyperlinkCommonProps = {
  children: ReactNode
  className?: string
  inline?: boolean
  type?: Type
  fontWeight?: FontWeight
  dataTarget?: string
  ariaLabel?: string
  role?: string
  buttonContentDataTarget?: string
}

type HyperlinkButtonProps = { onClick?: () => void } & HyperlinkCommonProps

export const HyperlinkButton = (props: HyperlinkButtonProps) => {
  const { children, type, fontWeight, buttonContentDataTarget } = props
  const htmlProps = getHtmlProps(props)
  return (
    <StyledButton {...htmlProps}>
      <HyperlinkTypography
        dataTarget={buttonContentDataTarget}
        type={type}
        fontWeight={fontWeight}
      >
        {children}
      </HyperlinkTypography>
    </StyledButton>
  )
}

type HyperlinkAnchorProps = { href?: string } & HyperlinkCommonProps

export const HyperlinkAnchor = (props: HyperlinkAnchorProps) => {
  const { children, type, fontWeight, buttonContentDataTarget } = props
  const htmlProps = getHtmlProps(props)
  return (
    <StyledAnchor {...htmlProps}>
      <HyperlinkTypography
        dataTarget={buttonContentDataTarget}
        type={type}
        fontWeight={fontWeight}
      >
        {children}
      </HyperlinkTypography>
    </StyledAnchor>
  )
}

const getHtmlProps = (props: HyperlinkButtonProps & HyperlinkAnchorProps) => {
  const { className, inline, href, onClick, dataTarget, ariaLabel, role } =
    props

  return {
    className,
    inline,
    href,
    onClick,
    ...(dataTarget ? { "data-target": dataTarget } : {}),
    ...(ariaLabel ? { "aria-label": ariaLabel } : {}),
    ...(role ? { role } : {}),
  }
}

type HyperlinkTypographyProps = {
  children?: ReactNode
  type?: Type
  fontWeight?: FontWeight
  dataTarget?: string
}
const HyperlinkTypography = (props: HyperlinkTypographyProps) => {
  const { children, type, fontWeight, dataTarget } = props
  return (
    <Typography
      type={type || "h4"}
      fontWeight={fontWeight || "bold"}
      css={css`
        color: inherit;
      `}
      data-target={dataTarget}
    >
      {children}
    </Typography>
  )
}

const getHyperlinkStyling = (inline: boolean | undefined) => css`
  background: none;
  display: ${inline ? "inline-block" : "block"};
  padding: ${inline ? "0" : `${indent(1)} ${indent(2)}`};
  margin: 0;
  border: ${inline ? "none" : "transparent solid 2px"};
  border-radius: ${inline ? "0" : "8px"};
  cursor: pointer;
  color: #${Blue};
  &:hover {
    color: #${Aqua};
  }
  &:active {
    color: #${Blue};
  }
  ${focusVisible((hasFocus) =>
    inline
      ? `text-decoration: ${hasFocus ? "underline" : "none"};`
      : `border-color: ${hasFocus ? `#${Aqua}` : "transparent"};`
  )}
`

const StyledButton = styled.button`
  ${({ inline }: { inline?: boolean }) => getHyperlinkStyling(inline)}
`

const StyledAnchor = styled.a`
  ${({ inline }: { inline?: boolean }) => getHyperlinkStyling(inline)}
`
