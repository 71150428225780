import React from "react"
import styled from "@emotion/styled"
import {
  VideoMute,
  MicMute,
  EndCall,
  DevicesSettings,
} from "@intouchhealth/iwa-videocall-ui/devices/Controls"
import { translation } from "locales"
import { N0 } from "@intouchhealth/ui-components/colors"
import { XSmallMobile } from "components/theme"
import { indent } from "@intouchhealth/ui-components/theme"

type Props = {
  className?: string
  disabled?: boolean
  hasAudio: boolean
  hasVideo: boolean
  isIdle?: boolean
  isDevicesSettingsModalShown: boolean
  openDevicesSettingsModal: () => void
  openEndCallModal: () => void
  toggleMicrophone: () => void
  toggleCamera: () => void
  controlsExitLabel: string
}

export const PureControls = ({
  className,
  disabled,
  hasAudio,
  hasVideo,
  isIdle,
  isDevicesSettingsModalShown,
  openDevicesSettingsModal,
  openEndCallModal,
  toggleMicrophone,
  toggleCamera,
  controlsExitLabel,
}: Props) => (
  <Container className={className}>
    <IdleSlider isIdle={isIdle}>
      <Group alignLeft />
      <Group alignCenter>
        <MicMute
          disabled={disabled}
          isOn={hasAudio}
          onClick={toggleMicrophone}
          onLabel={translation.videocall.controlLabel.disableMic}
          offLabel={translation.videocall.controlLabel.enableMic}
          showLabel
          dataTarget="mic-mute"
        />
        <EndCall
          disabled={disabled}
          onClick={openEndCallModal}
          label={controlsExitLabel}
          showLabel
          dataTarget="end-call"
        />
        <VideoMute
          disabled={disabled}
          isOn={hasVideo}
          onClick={toggleCamera}
          onLabel={translation.videocall.controlLabel.disableCamera}
          offLabel={translation.videocall.controlLabel.enableCamera}
          showLabel
        />
      </Group>
      <Group alignRight>
        <DevicesSettings
          disabled={disabled}
          isOn={isDevicesSettingsModalShown}
          onClick={openDevicesSettingsModal}
          onFill={N0}
          offFill={N0}
          label={translation.videocall.controlLabel.settings}
          showLabel
          dataTarget="devices-settings"
        />
      </Group>
    </IdleSlider>
  </Container>
)

const Container = styled.section`
  height: 96px;
  width: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;
`

const IdleSlider = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  transition: top 300ms ease;
  ${({ isIdle }: { isIdle?: boolean }) => (isIdle ? "top: 100%;" : "top: 0;")}
  &:focus-within {
    top: 0;
    transition: none;
  }
  background-image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  @media only screen and (max-width: 550px) {
    justify-content: center;
    grid-template-columns: repeat(3, auto);
  }
`

const Group = styled.section`
  display: flex;
  flex-direction: row;
  ${({
    alignLeft,
    alignCenter,
    alignRight,
  }: {
    alignLeft?: boolean
    alignCenter?: boolean
    alignRight?: boolean
  }) => {
    if (alignLeft) return "justify-content: flex-start;"
    else if (alignCenter) return "justify-content: center;"
    else if (alignRight) return "justify-content: flex-end;"
    else return ""
  }}
  > button {
    width: 80px;
    margin: 0 ${indent(1)};
    ${XSmallMobile} {
      margin: 0;
    }
  }
`
