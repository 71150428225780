import React, { ReactNode, useEffect } from "react"
import { started } from "./store"

type Props = {
  children: ReactNode
}

export const MarkStarted = (props: Props) => {
  const { children } = props
  useEffect(() => {
    started()
  }, [])
  return <>{children}</>
}
