import { createEvent, createStore } from "effector"
import { InviteDetails } from "./Model"

const defaultState: InviteDetails = {
  name: "",
  mobile: "",
  email: "",
}

export const setInviteDetails = createEvent<InviteDetails>()
export const resetInviteDetails = createEvent()

export const inviteDetailsStore = createStore(defaultState)
  .on(setInviteDetails, (_, inviteDetails) => inviteDetails)
  .reset(resetInviteDetails)
