import React from "react"
import { translation } from "locales"
import { AuthError } from "Auth/Error"

export const ActivationError = () => (
  <AuthError
    title={translation.patient.error.activation.title}
    body1={translation.patient.error.activation.body1}
    body2={translation.patient.error.activation.body2}
    support={translation.patient.error.support}
  />
)
