import { EventEmitter } from "events";
const eventEmitter = new EventEmitter();

export const debug = (message: string) => {
  eventEmitter.emit("logDebug", message);
};

export const warning = (message: string) => {
  eventEmitter.emit("logWarning", message);
};

export const error = (message: string) => {
  eventEmitter.emit("logError", message);
};

export const onDebug = (listener: (message: string) => void) => {
  eventEmitter.on("logDebug", listener);
  return () => {
    eventEmitter.off("logDebug", listener);
  };
};

export const onWarning = (listener: (message: string) => void) => {
  eventEmitter.on("logWarning", listener);
  return () => {
    eventEmitter.off("logWarning", listener);
  };
};

export const onError = (listener: (message: string) => void) => {
  eventEmitter.on("logError", listener);
  return () => {
    eventEmitter.off("logError", listener);
  };
};
