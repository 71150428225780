import { HttpError } from "@intouchhealth/web-connection/Http/HttpError"
import { Requester, Request } from "@intouchhealth/web-connection/Http/Request"

export const authorizedRequest =
  (fetch: Requester, getToken: () => string | undefined) =>
  async <T>(request: Request) => {
    const headers = request.headers
    const token = getToken()
    if (token) {
      headers["Authorization"] = `Bearer ${token}`
    }

    try {
      return await fetch<T>(request)
    } catch (e) {
      if (e instanceof HttpError) {
        // TODO: error handling
      }
      throw e
    }
  }
