import React from "react"
import styled from "@emotion/styled"
import { translation } from "locales"
import { NotFoundImage } from "components/Illustrations"
import { BodyText, TitleText } from "components/typography"

export const PageNotFound = () => {
  return (
    <MainContainer>
      <NotFoundImage />
      <TitleText>{translation.pageNotFound.title}</TitleText>
      <BodyText>{translation.pageNotFound.body}</BodyText>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
