import React from "react"
import { PureEnded } from "./PureEnded"
import { resetName } from "Patient/EnterName/store"
import { resetInviteDetails } from "Patient/Invite/store"

type Props = {
  onNewCall: () => void
}

export const Ended = ({ onNewCall }: Props) => {
  const wipeDataAndOnNewCall = () => {
    resetInviteDetails()
    resetName()
    onNewCall()
  }

  return <PureEnded onNewCall={wipeDataAndOnNewCall} />
}
