import React from "react"
import { translation } from "locales"
import { AuthError } from "Auth/Error"
import { getGuestInviteLinkFromStorage } from "Init/SessionStorage"
import { CopyLinkButton } from "components/CopyLinkButton"

export const InvitationError = () => {
  const guestInviteLink = getGuestInviteLinkFromStorage()
  return (
    <>
      <AuthError
        title={translation.guest.error.invitation.title}
        body1={translation.guest.error.invitation.body}
      />
      {guestInviteLink && <CopyLinkButton link={guestInviteLink} />}
    </>
  )
}
