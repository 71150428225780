import React from "react"
import styled from "@emotion/styled"
import { N50 } from "@intouchhealth/ui-components/colors"
import { indent, Mobile } from "@intouchhealth/ui-components/theme"
import { Button } from "@intouchhealth/ui-components/button"
import { translation } from "locales"
import { ShortLandscape } from "components/theme"
import { TermsAndConditionsText } from "components/TermsAndConditions/text"

type Props = {
  onAccept: () => void
}

export const TermsAndConditions = (props: Props) => {
  const { onAccept } = props
  return (
    <MainContainer>
      <TextContainer tabIndex={0}>
        <Wrapper>
          <TermsAndConditionsText />
        </Wrapper>
      </TextContainer>
      <HideOnShortLandscape>
        <Button
          data-target="accept-and-continue"
          themeName="whitelabel"
          onClick={onAccept}
          buttonContentDataTarget="accept-button-text"
        >
          {translation.buttonLabels.acceptAndContinue}
        </Button>
      </HideOnShortLandscape>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const TextContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: #${N50};
  white-space: pre-line;
  overflow-y: scroll;
  margin-bottom: ${indent(2)};
`

const Wrapper = styled.div`
  padding: ${indent(3)};
  ${Mobile} {
    padding: ${indent(2)};
  }
`

const HideOnShortLandscape = styled.div`
  margin-top: ${indent(3)};
  margin-bottom: ${indent(5)};
  ${ShortLandscape} {
    display: none;
  }
  ${Mobile} {
    margin-top: 0;
    margin-bottom: ${indent(2)};
  }
`
