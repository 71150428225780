import React from "react"
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router"
import { AuthenticatedContext } from "Auth"
import { Layout } from "components/Layout"
import { BlockNavigation } from "components/BlockNavigation"
import { PageNotFound } from "components/PageNotFound"
import {
  toEnded,
  toInvalidCodeError,
  toInvitationError,
  toLeftCall,
  toStart,
  toVideocall,
} from "./Router"
import { InvitationError, InvitationParse } from "./Invitation"
import { Videocall } from "./Videocall"
import { LeftCall } from "./LeftCall"
import { Ended } from "./Ended"
import { getTokenFromStorage } from "./Invitation"
import { Start } from "./Start"
import { toTermsAndConditions } from "Router"

export const GuestRoutes = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const onBack = () => history.goBack()
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/start`}
        render={() => (
          <Layout>
            <Start
              onViewTermsAndConditions={() =>
                history.push(toTermsAndConditions())
              }
              onContinue={(videocallId) =>
                history.replace(toVideocall(videocallId))
              }
              onCodeAlreadyUsed={() => history.replace(toInvitationError())}
              onInvalidCode={() => history.replace(toInvalidCodeError())}
            />
          </Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/invitation-error`}
        render={() => (
          <Layout>
            <InvitationError />
          </Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/invalid-code`}
        render={() => (
          <Layout>
            <PageNotFound />
          </Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/videocall/:videocallId`}
        render={({ match }: RouteComponentProps<{ videocallId: string }>) => (
          <AuthenticatedContext
            token={getTokenFromStorage()}
            onError={() => history.replace(toInvitationError())}
          >
            <BlockNavigation>
              {(navigationBlocked) => (
                <Videocall
                  videocallId={match.params.videocallId}
                  callEndedPath={toEnded()}
                  leftCallPath={toLeftCall()}
                  navigationBlocked={navigationBlocked}
                />
              )}
            </BlockNavigation>
          </AuthenticatedContext>
        )}
      />
      <Route
        exact
        path={`${match.url}/left-call`}
        render={() => (
          <Layout>
            <LeftCall onRejoin={onBack} />
          </Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/ended`}
        render={() => (
          <Layout>
            <Ended />
          </Layout>
        )}
      />
      <Route
        render={() => (
          <Layout>
            <PageNotFound />
          </Layout>
        )}
      />
    </Switch>
  )
}

export const InvitationParseRoute = () => {
  const history = useHistory()
  const match = useRouteMatch<{ accessCode: string }>()
  return (
    <InvitationParse
      accessCode={match.params.accessCode}
      onContinue={() => history.replace(toStart())}
    />
  )
}
