import React from "react"
import styled from "@emotion/styled"
import { CloseIcon } from "@intouchhealth/ui-components/icons"
import { N400, Aqua } from "@intouchhealth/ui-components/colors"
import { focusVisible } from "@intouchhealth/ui-components/theme"

type Props = {
  className?: string
  onClick: () => void
}

export const CloseModalButton = (props: Props) => {
  return (
    <CloseButton
      data-target="close-modal-button"
      className={props.className}
      onClick={props.onClick}
    >
      <CloseIcon iconSize="l" fill={N400} />
    </CloseButton>
  )
}

const CloseButton = styled.button`
  height: 40px;
  width: 40px;
  padding: 0;
  background: none;
  border: transparent solid 2px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${focusVisible(
    (hasFocus) => `
      border-color: ${hasFocus ? `#${Aqua}` : "transparent"};
    `
  )}
`
