import styled from "@emotion/styled"
import RawChromeImage from "./img/chrome.svg?inline"
import RawFirefoxImage from "./img/firefox.svg?inline"
import RawSafariImage from "./img/safari.svg?inline"
import { ComponentType, HTMLAttributes } from "react"

export const UnsupportedBrowserMobileBreakpoint = "@media (max-width: 550px)"

const getImageComponents = (
  rawImage: ComponentType<HTMLAttributes<SVGElement>>
) => {
  const adaptiveImage = styled(rawImage)`
    width: 60px;
    height: 60px;
    ${UnsupportedBrowserMobileBreakpoint} {
      width: 40px;
      height: 40px;
    }
  `
  const fadedAdaptiveImage = styled(adaptiveImage)`
    opacity: 0.5;
    filter: grayscale(100%);
  `
  return [adaptiveImage, fadedAdaptiveImage]
}

export const [AdaptiveChromeImage, FadedAdaptiveChromeImage] =
  getImageComponents(RawChromeImage)

export const [AdaptiveFirefoxImage, FadedAdaptiveFirefoxImage] =
  getImageComponents(RawFirefoxImage)

export const [AdaptiveSafariImage, FadedAdaptiveSafariImage] =
  getImageComponents(RawSafariImage)
