import React from "react"
import styled from "@emotion/styled"
import { translation } from "locales"
import { indent, Mobile } from "@intouchhealth/ui-components/theme"
import { MomChildImage } from "components/Illustrations"
import { TitleText } from "components/typography"

export const Ended = () => {
  return (
    <MainContainer>
      <MomChildImage />
      <TitleText>{translation.guest.ended.title}</TitleText>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${Mobile} {
    padding-top: ${indent(3)};
  }
`
