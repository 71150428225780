import React, { useEffect, useState } from "react"
import {
  AuthorizeResponse,
  devicesAccessIssuesErrorStore,
  PureVideocall,
  sessionStore,
  setOpenEndCallModalOnLoadInStorage,
  getOpenEndCallModalOnLoadFromStorage,
} from "Videocall"
import { translation } from "locales"
import { Event } from "effector"
import { useStore } from "effector-react"
import { http } from "Patient/Http"
import { getTokenFromStorage } from "Patient/Activation/SessionStorage"
import {
  useVideocall,
  participants as participantsStore,
} from "@intouchhealth/videocall"
import { leaveVideoCall } from "@intouchhealth/videocall/end-video-call"
import { EndCallModal } from "./Controls/EndCallModal"
import { invitesSentRecentlyStore } from "Patient/Review/store"

type Props = {
  videocallId: string
  callEndedPath: string
  navigationBlocked: Event<void>
}

export const Videocall = ({
  videocallId,
  callEndedPath,
  navigationBlocked,
}: Props) => {
  const invitesSentRecently = useStore(invitesSentRecentlyStore)
  const participants = useStore(participantsStore)
  const session = useStore(sessionStore)
  const devicesAccessIssuesError = useStore(devicesAccessIssuesErrorStore)

  const [isEndCallModalOpen, setEndCallModalOpen] = useState(false)
  useEffect(() => {
    const unwatch = navigationBlocked.watch(() => {
      setOpenEndCallModalOnLoadInStorage(true)
      leaveVideoCall(window.location.href)
    })

    return () => unwatch()
  }, [navigationBlocked])
  useEffect(() => {
    if (getOpenEndCallModalOnLoadFromStorage()) {
      setOpenEndCallModalOnLoadInStorage(false)
      setEndCallModalOpen(true)
    }
  }, [])

  const [selfId, setSelfId] = useState<undefined | string>(undefined)
  useEffect(() => {
    void http
      .post<AuthorizeResponse>(`/videocall/${videocallId}/authorize`, {})
      .then(({ participantId }) => {
        setSelfId(participantId)
      })
      .catch(({ statusCode }) => {
        if (statusCode === 404) {
          leaveVideoCall(callEndedPath)
        }
      })
  }, [])

  const [cameraPublished, setCameraPublished] = useState(false)
  useEffect(() => {
    if (!cameraPublished) {
      const self = participants.find((p) => p.id === selfId)
      if (self?.cameraStream) {
        setCameraPublished(true)
      }
    }
  }, [participants, selfId])

  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(false)
  useEffect(() => {
    const minimumTime = invitesSentRecently ? 4000 : 2000
    const timeoutId = window.setTimeout(
      () => setMinimumTimeElapsed(true),
      minimumTime
    )

    return () => window.clearTimeout(timeoutId)
  }, [])

  const getShowInterstitial = () => !(cameraPublished && minimumTimeElapsed)

  const endCall = () => {
    session?.signal({ type: "endCall" }, () => {
      // TODO: Handle or log errors
    })

    void http.post(`/videocall/${videocallId}/end`, {})
    leaveVideoCall(callEndedPath)
  }

  useVideocall({
    videocallId,
    authToken: getTokenFromStorage(),
    enablePingPong: false,
    reauthorizeVideocallAnalytics: false,
  })

  return (
    <PureVideocall
      showInterstitial={getShowInterstitial()}
      interstitialMessage={
        invitesSentRecently
          ? translation.patient.videocall.invitingAndStartingVideo
          : translation.patient.videocall.startingVideo
      }
      showMainVideoMessageSpinner={true}
      mainVideoMessage={translation.patient.videocall.waiting}
      devicesAccessIssuesError={devicesAccessIssuesError}
      openEndCallModal={() => setEndCallModalOpen(true)}
      endCallModal={
        <EndCallModal
          onButtonClick={endCall}
          isOpen={isEndCallModalOpen}
          onClose={() => setEndCallModalOpen(false)}
        />
      }
      controlsExitLabel={translation.videocall.controlLabel.endCall}
      participants={participants}
      selfId={selfId}
    />
  )
}
