import React, { useState } from "react"
import styled from "@emotion/styled"
import { N500 } from "@intouchhealth/ui-components/colors"
import { NewField } from "@intouchhealth/ui-components/FormElements/Field"
import { PureInput } from "@intouchhealth/ui-components/input"
import {
  EmailIcon,
  NewUserIcon,
  PhoneIcon,
} from "@intouchhealth/ui-components/icons"
import { indent, Mobile } from "@intouchhealth/ui-components/theme"
import { Button } from "@intouchhealth/ui-components/button"
import { translation } from "locales"
import { InviteDetails } from "./Model"
import { isEmailAddressValid } from "Patient/ValidationSanitization/email"
import {
  getFormattedMobileNumber,
  isMobileNumberValid,
} from "Patient/ValidationSanitization/mobile"
import { TitleText } from "components/typography"

type Props = {
  onContinue: (inviteDetails: InviteDetails) => void
  initialInviteDetails: InviteDetails
}

export const PureInvite = ({ onContinue, initialInviteDetails }: Props) => {
  const [name, setName] = useState(initialInviteDetails.name)
  const [mobile, setMobile] = useState(initialInviteDetails.mobile)
  const [displayMobileWarning, setDisplayMobileWarning] = useState(false)
  const [email, setEmail] = useState(initialInviteDetails.email)
  const [displayEmailWarning, setDisplayEmailWarning] = useState(false)

  const onMobileNumberChange = (value: string) => {
    const formattedNumber = getFormattedMobileNumber(value)
    setMobile(formattedNumber)
    setDisplayMobileWarning(false)
  }

  const onEmailAddressChange = (value: string) => {
    setEmail(value)
    setDisplayEmailWarning(false)
  }

  const isContinueButtonEnabled = () => {
    return (
      name.length > 0 &&
      !displayMobileWarning &&
      !displayEmailWarning &&
      (mobile.length > 0 || email.length > 0)
    )
  }

  const onContinueButtonClick = () => {
    const mobileIsValid = mobile.length === 0 || isMobileNumberValid(mobile)
    const emailIsValid = email.length === 0 || isEmailAddressValid(email)
    if (!mobileIsValid || !emailIsValid) {
      setDisplayMobileWarning(!mobileIsValid)
      setDisplayEmailWarning(!emailIsValid)
    } else {
      onContinue({ name, mobile, email })
    }
  }

  return (
    <MainContainer>
      <TextContainer>
        <TitleText>{translation.patient.invite.title}</TitleText>
      </TextContainer>
      <InputFieldsContainer>
        <NewField
          label={translation.patient.invite.inputLabels.name}
          labelFontType="h5"
        />
        <PureInput
          icon={<NewUserIcon fill={N500} />}
          iconTabIndex={-1}
          iconAriaHidden
          onChange={setName}
          value={name}
          maxLength={70}
          dataTarget="guest-name-input"
        />
        <ContactInfoContainer>
          <ContactInfoFieldContainer>
            <NewField
              label={translation.patient.invite.inputLabels.mobileNumber}
              labelFontType="h5"
            />
            <PureInput
              onChange={onMobileNumberChange}
              value={mobile}
              invalidWarning={displayMobileWarning}
              icon={<PhoneIcon fill={N500} />}
              iconTabIndex={-1}
              iconAriaHidden
              dataTarget="mobile-number-input"
            />
            {displayMobileWarning && (
              <NewField
                footnoteType="error"
                footnote={translation.patient.invite.inputWarnings.mobileNumber}
              />
            )}
          </ContactInfoFieldContainer>
          <ContactInfoFieldContainer>
            <NewField
              label={translation.patient.invite.inputLabels.emailAddress}
              labelFontType="h5"
            />
            <PureInput
              type="email"
              onChange={onEmailAddressChange}
              value={email}
              invalidWarning={displayEmailWarning}
              icon={<EmailIcon fill={N500} />}
              iconTabIndex={-1}
              iconAriaHidden
              maxLength={254}
              dataTarget="email-address-input"
            />
            {displayEmailWarning && (
              <NewField
                footnoteType="error"
                footnote={translation.patient.invite.inputWarnings.emailAddress}
              />
            )}
          </ContactInfoFieldContainer>
        </ContactInfoContainer>
      </InputFieldsContainer>
      <Button
        size="m"
        themeName="whitelabel"
        onClick={onContinueButtonClick}
        disabled={!isContinueButtonEnabled()}
        data-target="continue-button"
      >
        {translation.buttonLabels.continue}
      </Button>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${indent(8)};
  ${Mobile} {
    padding-top: ${indent(5)};
  }
`

const ContactInfoContainer = styled.div`
  display: flex;
  padding-top: ${indent(1)};
  ${Mobile} {
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: ${indent(4)};
  }
  justify-content: space-between;
`

const ContactInfoFieldContainer = styled.div`
  width: 48%;
  padding-top: ${indent(2)};
  padding-bottom: ${indent(4)};
  ${Mobile} {
    width: 100%;
    padding-bottom: 0px;
  }
`
