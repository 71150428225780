import { HTMLAttributes, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { squareSize } from "@intouchhealth/ui-components/theme"

export const SmallMobile = "@media only screen and (max-width: 375px)"
export const XSmallMobile = "@media only screen and (max-width: 320px)"
export const ShortLandscape =
  "@media only screen and (max-height: 766px) and (orientation: landscape)"

export const ScaledIllustration = (
  svg: React.ComponentType<HTMLAttributes<SVGElement>>
) => styled(svg)`
  ${squareSize(304)}
  ${SmallMobile} {
    ${squareSize(221)}
  }
  ${XSmallMobile} {
    ${squareSize(192)}
  }
`

export const useLayoutViewportHeight = () => {
  const [layoutViewportHeight, setLayoutViewportHeight] = useState(
    document.documentElement.clientHeight
  )

  useEffect(() => {
    const listener = () =>
      setLayoutViewportHeight(document.documentElement.clientHeight)
    window.addEventListener("resize", listener)
    return () => window.removeEventListener("resize", listener)
  }, [])

  return layoutViewportHeight
}
