import { shuffle } from "lodash";
import type { TurnCredentials, TurnServer } from "./Model";
import { findBestTurnServerUrl } from "./Reachability";
import { debug, warning } from "../logger";

// Number of TURN Servers for UDP relay
export const NUM_UDP_TURN_SERVERS = 6;

// Number of TURN Servers for TCP relay
export const NUM_TCP_TURN_SERVERS = 6;

// Port for UDP relay
const UDP_TURN_PORT = 35001;

// Port for TCP relay
const TCP_TURN_PORT = 443;

// Timeout for TURN reachability in milliseconds
export const TURN_TIMEOUT_MS = 2000;

// How much longer we wait for the UDP test if the TCP test finishes first.
export const UDP_GRACE_PERIOD_MS = 500;

const rejectTimeout = (timeout: number) =>
  new Promise<never>((resolve, reject) => setTimeout(reject, timeout));

type Protocol = "udp" | "tcp"

type UseSingleTurnServerTrueOptions = {
  useSingleTurnServer: true
  turnTimeout: number
  udpGracePeriod: number
};
type UseSingleTurnServerFalseOptions = {
  useSingleTurnServer: false
};
type UseSingleTurnServerOptions = UseSingleTurnServerTrueOptions | UseSingleTurnServerFalseOptions
export type GetTurnServerOptions = UseSingleTurnServerOptions

export const getTurnServer = async (
  udpUrls: string[],
  tcpUrls: string[],
  turnCredentials: TurnCredentials,
  options: GetTurnServerOptions
): Promise<TurnServer> => {
  const urls: string[] = [];
  if (options.useSingleTurnServer) {
    const {
      turnTimeout,
      udpGracePeriod,
    } = options;
    const [udpTest, tcpTest] = [udpUrls, tcpUrls]
      .map(urls => findBestTurnServerUrl(urls, turnCredentials, turnTimeout));

    // Get the protocol of the first test that resolves.
    const firstProtocol = await Promise.any<Protocol>([
      udpTest.then(() => "udp"),
      tcpTest.then(() => "tcp")
    ]).catch(() => undefined);

    switch(firstProtocol) {
      case "udp": {
        const udpUrl = await udpTest.catch(() => undefined);
        if (udpUrl) {
          urls.push(udpUrl);
        }
        break;
      }
      case "tcp": {
        const tcpUrl = await tcpTest.catch(() => undefined);

        // Wait udpGracePeriod ms more for a UDP URL.
        const udpUrl = await Promise.race([
          udpTest,
          rejectTimeout(udpGracePeriod)
        ]).catch(() => undefined);

        // Use the UDP URL if we have one, otherwise use the TCP URL.
        const url = udpUrl || tcpUrl;
        if (url) {
          urls.push(url);
        }

        break;
      }
    }
  }

  // If we don't have a URL at this point, provide all of the URLs.
  if (!urls.length) {
    if (options.useSingleTurnServer) {
      warning("useSingleTurnServer is 'true' but the server reachability tests did not return a TURN server URL. Falling back to providing all TURN server URLs.");
    }

    urls.push(...shuffle(udpUrls));
    urls.push(...shuffle(tcpUrls));
  }

  debug(`Session options TURN URLs: ${urls}`);

  return {
    urls,
    ...turnCredentials
  };
};

const getPort = (protocol: Protocol): number => {
  switch (protocol) {
    case "udp": return UDP_TURN_PORT;
    case "tcp": return TCP_TURN_PORT;
  }
};

const getNumServers = (protocol: Protocol): number => {
  switch (protocol) {
    case "udp": return NUM_UDP_TURN_SERVERS;
    case "tcp": return NUM_TCP_TURN_SERVERS;
  }
};

export const createTurnUrl = (turnDomain: string, protocol: Protocol, index: number) =>
  `turn:turn${index}.${protocol}.${turnDomain}:${getPort(protocol)}?transport=${protocol}`;

export const createTurnUrlList = (turnDomain: string, protocol: Protocol) => {
  const urls: string[] = [];
  for (let i = 1; i <= getNumServers(protocol); i++) {
    urls.push(createTurnUrl(turnDomain, protocol, i));
  }

  return urls;
};
