import React, { useEffect } from "react"
import { setAccessCodeInStorage } from "./SessionStorage"

type Props = {
  accessCode: string
  onContinue: () => void
}

export const InvitationParse = (props: Props) => {
  const { accessCode, onContinue } = props

  useEffect(() => {
    setAccessCodeInStorage(accessCode)
    onContinue()
  }, [])
  return <></>
}
