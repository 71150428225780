import React from "react"
import styled from "@emotion/styled"
import { Loader } from "@intouchhealth/ui-components/loader"
import { Typography } from "@intouchhealth/ui-components/typography"
import { indent, Mobile } from "@intouchhealth/ui-components/theme"
import { N0, N1000 } from "@intouchhealth/ui-components/colors"

type Props = {
  message?: string
  showSpinner?: boolean
}
export const Overlay = (props: Props) => {
  const { message, showSpinner } = props
  return (
    <MainContainer>
      <Content>
        {showSpinner && (
          <LoaderContainer data-target="loader-container">
            <Loader fill={N0} />
          </LoaderContainer>
        )}
        {showSpinner && message && <Spacer />}
        {message && (
          <TextContainer>
            <Typography type="h2" fontColor="N0" data-target="overlay-message">
              {message}
            </Typography>
          </TextContainer>
        )}
      </Content>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${indent(4)};
  ${Mobile} {
    padding: 0 ${indent(3)};
  }
  background-color: #${N1000};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoaderContainer = styled.div`
  > * {
    > svg {
      height: 48px;
      width: 48px;
    }
  }
`

const Spacer = styled.div`
  height: ${indent(2)};
`

const TextContainer = styled.div`
  text-align: center;
`
