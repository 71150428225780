export const toActivation = () => `/activate`

const patientPath = "/patient"
export const toActivationError = () => `${patientPath}/activation-error`
export const toReactivation = () => `${patientPath}/reactivate`
export const toUnauthorized = () => `${patientPath}/unauthorized`
export const toStart = () => `${patientPath}/start`
export const toEnterName = () => `${patientPath}/enter-name`
export const toInvite = () => `${patientPath}/invite`
export const toReview = () => `${patientPath}/review`
export const toEnded = () => `${patientPath}/ended`
export const toVideocall = (videocallId: string) =>
  `${patientPath}/videocall/${videocallId}`
