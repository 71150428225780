import React from "react"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import { GlobalStyles } from "Init/style"
import { Routes } from "routes"

export const browserHistory = createBrowserHistory()

export function App() {
  return (
    <GlobalStyles>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </GlobalStyles>
  )
}
