const PatientTokenStorageKey = "fnf.patient.token"

export const setTokenInStorage = (token: string) => {
  sessionStorage.setItem(PatientTokenStorageKey, token)
}

export const getTokenFromStorage = () =>
  sessionStorage.getItem(PatientTokenStorageKey) || undefined

const ActivationCodeStorageKey = "fnf.patient.code"

export const setCodeInStorage = (code: string) => {
  sessionStorage.setItem(ActivationCodeStorageKey, code)
}

export const getCodeFromStorage = () =>
  sessionStorage.getItem(ActivationCodeStorageKey)
