import React from "react"
import { hot } from "react-hot-loader/root"
import { App } from "./App"
import { render } from "react-dom"
import { initSentry } from "./error/sentryClient"

initSentry()

const appEl = document.getElementById("wrap")
if (appEl) {
  const HotApp = hot(App)
  render(<HotApp />, appEl)
}
