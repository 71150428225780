import React, { useEffect, useState } from "react"
import { Overlay } from "./Overlay"

type Props = {
  showMessageSpinner?: boolean
  message: string
}

export const NoRemoteParticipant = (props: Props) => {
  const { showMessageSpinner, message } = props

  const [showMessage, setShowMessage] = useState(false)
  useEffect(() => {
    const gracePeriod = 5000
    const timeoutId = window.setTimeout(() => setShowMessage(true), gracePeriod)

    return () => window.clearTimeout(timeoutId)
  }, [])

  return (
    <Overlay
      showSpinner={showMessage ? showMessageSpinner : true}
      message={showMessage ? message : undefined}
    />
  )
}
