import React from "react"
import styled from "@emotion/styled"
import { useLayoutViewportHeight } from "components/theme"

type Props = {
  children: React.ReactNode
  className?: string
  heightVH?: number
  heightPX?: number
  minHeightVH?: number
  minHeightPX?: number
  maxHeightVH?: number
  maxHeightPX?: number
}

const ratio = 4 / 3

const getWidthFromHeight = (height: number) => height * ratio

const getCssValue = (
  viewportHeightPX: number,
  dimensionVH: number,
  dimensionPX: number
) =>
  dimensionVH || dimensionPX
    ? `${((viewportHeightPX * dimensionVH) / 100 + dimensionPX).toFixed(2)}px`
    : "initial"

export const AspectRatio = (props: Props) => {
  const {
    children,
    className,
    heightVH = 0,
    heightPX = 0,
    minHeightVH = 0,
    minHeightPX = 0,
    maxHeightVH = 0,
    maxHeightPX = 0,
  } = props

  const viewportHeightPX = useLayoutViewportHeight()

  const getHeight = () => getCssValue(viewportHeightPX, heightVH, heightPX)
  const getMinHeight = () =>
    getCssValue(viewportHeightPX, minHeightVH, minHeightPX)
  const getMaxHeight = () =>
    getCssValue(viewportHeightPX, maxHeightVH, maxHeightPX)

  const widthVH = getWidthFromHeight(heightVH)
  const widthPX = getWidthFromHeight(heightPX)
  const minWidthVH = getWidthFromHeight(minHeightVH)
  const minWidthPX = getWidthFromHeight(minHeightPX)
  const maxWidthVH = getWidthFromHeight(maxHeightVH)
  const maxWidthPX = getWidthFromHeight(maxHeightPX)

  const getWidth = () => getCssValue(viewportHeightPX, widthVH, widthPX)
  const getMinWidth = () =>
    getCssValue(viewportHeightPX, minWidthVH, minWidthPX)
  const getMaxWidth = () =>
    getCssValue(viewportHeightPX, maxWidthVH, maxWidthPX)

  return (
    <MainContainer
      className={className}
      height={getHeight()}
      minHeight={getMinHeight()}
      maxHeight={getMaxHeight()}
      width={getWidth()}
      minWidth={getMinWidth()}
      maxWidth={getMaxWidth()}
    >
      {children}
    </MainContainer>
  )
}

type MainContainerProps = {
  height: string
  minHeight: string
  maxHeight: string
  width: string
  minWidth: string
  maxWidth: string
}

const MainContainer = styled.div`
  ${({
    height,
    minHeight,
    maxHeight,
    width,
    minWidth,
    maxWidth,
  }: MainContainerProps) => `
    height: ${height};
    min-height: ${minHeight};
    max-height: ${maxHeight};
    width: ${width};
    min-width: ${minWidth};
    max-width: ${maxWidth};
  `}
`
