import type { TurnCredentials } from "./Model";
import { debug, warning } from "../logger";

export const findBestTurnServerUrl = async (
  urls: string[],
  turnCredentials: TurnCredentials,
  timeout: number
): Promise<string> => {
  const tests = urls.map(url => reachabilityTest(url, turnCredentials, timeout).then(() => url));
  const url = await Promise.any(tests);
  return url;
};

export const reachabilityTest = (
  url: string,
  turnCredentials: TurnCredentials,
  timeout: number
): Promise<number> => {
  let timeoutId: number;
  let beginTime: number;
  let elapsedTime: number;
  let peerConnection: RTCPeerConnection | null = null;

  return new Promise<void>((resolve, reject) => {
    timeoutId = window.setTimeout(
      () => reject(new Error(`Timed out after ${timeout}ms`)),
      timeout
    );

    // Create a PeerConnection with no streams.
    const config: RTCConfiguration = {
      iceServers: [{ urls: url, ...turnCredentials }],
      iceTransportPolicy: "relay",
      iceCandidatePoolSize: 0
    };

    const offerOptions: RTCOfferOptions = {};

    peerConnection = new RTCPeerConnection(config);

    // Force a m=audio line.
    if ("addTransceiver" in RTCPeerConnection.prototype) {
      peerConnection.addTransceiver("audio", { direction: "recvonly" });
    } else {
      // Legacy. Not supported in Safari.
      offerOptions.offerToReceiveAudio = true;
    }

    peerConnection.onicecandidate = (event) => {
      if (!peerConnection) return;
      const { candidate } = event;
      if (candidate) {
        // Ignore the end-of-candidates indication candidate.
        if (candidate.candidate === "") return;
        debug(`Reachability test for ${url}: candidate found`);
        resolve();
      }
    };

    peerConnection.onicegatheringstatechange = () => {
      if (!peerConnection) return;
      if (peerConnection.iceGatheringState !== "complete") return;
      debug(`Reachability test for ${url}: gathering complete`);
    };

    peerConnection.onicecandidateerror = ({errorCode, errorText}) => {
      if (!peerConnection) return;
      const fatal = isFatalError(errorCode, errorText);
      const message = `Reachability test for ${url}: ${fatal ? "Fatal" : "Nonfatal"} error ${errorCode}: ${errorText}`;
      if (fatal) {
        warning(message);
        reject(new Error(`ICE candidate error ${errorCode}: ${errorText}`));
      } else {
        debug(message);
      }
    };

    peerConnection.createOffer(offerOptions)
      .then((description) => {
        if (!peerConnection) return;
        beginTime = window.performance.now();
        peerConnection.setLocalDescription(description);
      })
      .catch(() => reject(new Error("Failed to create an offer.")));
  })
    .then(() => {
      elapsedTime = window.performance.now() - beginTime;
      debug(`Reachability test succeeded for ${url}: ${Math.floor(elapsedTime)}ms`);
      return elapsedTime;
    })
    .catch((reason) => {
      debug(`Reachability test failed for ${url}: ${reason}`);
      throw reason;
    })
    .finally(() => {
      clearTimeout(timeoutId);
      if (peerConnection) {
        peerConnection.close();
        peerConnection = null;
      }
    });
};

const nonFatalErrors: ReadonlyMap<number, string[]> = new Map([
  [600, ["desired network interface"]],
  [701, ["host lookup"]]
]);

const isFatalError = (errorCode: number, errorText: string) => {
  const nonFatalSubStrings = nonFatalErrors.get(errorCode);
  const isNonFatal = nonFatalSubStrings?.some((str) => errorText.includes(str));
  return !isNonFatal;
};
