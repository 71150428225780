import {
  FetchRequest,
  WithBaseUrl,
  WithClientName,
} from "@intouchhealth/web-connection/Http/FetchRequest"

export const baseFetchRequest = WithClientName(
  WithBaseUrl(FetchRequest, "/api/fnf"),
  "fnf"
)
