import React, { ReactNode, useEffect } from "react"
import { useStore } from "effector-react"
import { startedStore } from "./store"

type Props = {
  children: ReactNode
  onNotStarted: () => void
}

export const CheckStarted = (props: Props) => {
  const { children, onNotStarted } = props
  const started = useStore(startedStore)
  useEffect(() => {
    if (!started) {
      onNotStarted()
    }
  }, [])
  return started ? <>{children}</> : null
}
