import React from "react"
import styled from "@emotion/styled"
import { Typography } from "@intouchhealth/ui-components/typography"
import { indent, Mobile } from "@intouchhealth/ui-components/theme"
import { N500, N600 } from "@intouchhealth/ui-components/colors"
import { translation } from "locales"
import { EmailIcon, PhoneIcon } from "@intouchhealth/ui-components/icons"
import { InviteDetails } from "Patient/Invite/Model"
import { LargePositiveButton } from "components/LargePositiveButton"
import { TitleText } from "components/typography"
import { HyperlinkButton } from "components/Hyperlink"

type PureReviewProps = {
  patientName: string
  inviteDetails: InviteDetails
  onEditPatient: () => void
  onEditFnf: () => void
  onContinue: () => void
}

export const PureReview = ({
  patientName,
  inviteDetails,
  onEditFnf,
  onEditPatient,
  onContinue,
}: PureReviewProps) => {
  const { name: guestName, mobile, email } = inviteDetails
  return (
    <MainContainer>
      <TextContainer>
        <TitleText>{translation.patient.review.title}</TitleText>
      </TextContainer>
      <InformationContainer>
        <Typography type="h3" fontColor="N500">
          {translation.patient.review.labels.you}
        </Typography>
        <PatientInformationContainer data-target="patient-information-container">
          <WrappedTypography
            type="h3"
            fontColor="N900"
            data-target="patient-name"
          >
            {patientName}
          </WrappedTypography>
          <HyperlinkButton
            onClick={onEditPatient}
            type="h3"
            fontWeight="medium"
            dataTarget="edit-patient"
          >
            {translation.patient.review.labels.edit}
          </HyperlinkButton>
        </PatientInformationContainer>
        <LineDivider color={N500} />
        <Typography type="h3" fontColor="N500">
          {translation.patient.review.labels.guest}
        </Typography>
        <FriendsAndFamilyInformationContainer data-target="guest-information-container">
          <WrappedTypography type="h3" data-target="guest-name">
            {guestName}
          </WrappedTypography>
          <HyperlinkButton
            onClick={onEditFnf}
            type="h3"
            fontWeight="medium"
            dataTarget="edit-guest"
          >
            {translation.patient.review.labels.edit}
          </HyperlinkButton>
        </FriendsAndFamilyInformationContainer>
        <EmailAndMobile email={email} mobile={mobile} />
      </InformationContainer>
      <LargePositiveButton
        onClick={onContinue}
        buttonText={translation.buttonLabels.inviteAndStart}
        dataTarget="invite-start-button"
      />
    </MainContainer>
  )
}

type EmailAndMobileProps = {
  email: string
  mobile: string
}

const EmailAndMobile = ({ email, mobile }: EmailAndMobileProps) => {
  return (
    <EmailAndMobileContainer data-target="email-and-mobile-container">
      {mobile.length > 0 && (
        <IconAndTextContainer>
          <PaddedPhoneIcon fill={N600} iconSize="m" />
          <Typography type="h3" data-target="guest-mobile">
            {mobile}
          </Typography>
        </IconAndTextContainer>
      )}
      {email.length > 0 && (
        <IconAndTextContainer>
          <PaddedEmailIcon fill={N600} iconSize="m" />
          <WrappedTypography type="h3" data-target="guest-email">
            {email}
          </WrappedTypography>
        </IconAndTextContainer>
      )}
    </EmailAndMobileContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InformationContainer = styled.div`
  padding-top: ${indent(5)};
  padding-bottom: ${indent(4)};
  ${Mobile} {
    padding-bottom: 0px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const PatientInformationContainer = styled.div`
  width: 100%;
  padding-top: ${indent(2.5)};
  padding-bottom: ${indent(1)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LineDivider = styled.hr`
  width: 100%;
  height: 1px;
  margin-bottom: ${indent(3)};
  ${({ color }: { color: string }) => `
    color: #${color};
  `}
`

const FriendsAndFamilyInformationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${indent(2)};
  padding-bottom: 0;
  ${Mobile} {
    padding-bottom: ${indent(1)};
  }
`

const EmailAndMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${Mobile} {
    margin-bottom: ${indent(2)};
  }
`

const PaddedPhoneIcon = styled(PhoneIcon)`
  margin-right: ${indent(2)};
  flex-shrink: 0;
`

const PaddedEmailIcon = styled(EmailIcon)`
  margin-right: ${indent(2)};
  flex-shrink: 0;
`

const IconAndTextContainer = styled.div`
  display: flex;
  min-height: 32px;
`

const WrappedTypography = styled(Typography)`
  word-break: break-word;
`
