import React from "react"
import { translation } from "locales"
import { HyperlinkButton } from "components/Hyperlink"

type Props = {
  className?: string
  dataTarget?: string
}

export const PrivacyPolicyLink = (props: Props) => {
  return (
    <HyperlinkButton
      className={props.className}
      fontWeight="bold"
      onClick={() => {
        window.open("https://teladochealth.com/privacy-policy")
      }}
      dataTarget={props.dataTarget}
    >
      {translation.legal.privacyPolicy}
    </HyperlinkButton>
  )
}
