import React, { useState } from "react"
import { useStore } from "effector-react"
import { PureEnterName } from "./PureEnterName"
import { setName, nameStore } from "./store"
import { sanitizeName } from "Patient/ValidationSanitization/name"

type Props = {
  onContinue: () => void
}

export const EnterName = (props: Props) => {
  const { onContinue } = props

  const savedName = useStore(nameStore)
  const [unsavedName, setUnsavedName] = useState(savedName)

  const saveNameAndContinue = () => {
    const name = sanitizeName(unsavedName)
    setName(name)
    onContinue()
  }

  return (
    <PureEnterName
      onContinue={saveNameAndContinue}
      name={unsavedName}
      onNameChange={setUnsavedName}
    />
  )
}
