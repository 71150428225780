import React from "react"
import { translation } from "locales"
import { InfoIcon } from "@intouchhealth/ui-components/icons"
import { X400 } from "@intouchhealth/ui-components/colors"
import { EndCallModal as SharedEndCallModal } from "Videocall/Controls/EndCallModal"

type Props = {
  onButtonClick: () => void
  isOpen: boolean
  onClose: () => void
}

export const EndCallModal = (props: Props) => {
  const { onButtonClick, isOpen, onClose } = props
  return (
    <SharedEndCallModal
      headerText={translation.patient.videocall.endCallModal.header}
      buttonText={translation.buttonLabels.endCall}
      icon={<InfoIcon iconSize="l" fill={X400} />}
      onButtonClick={onButtonClick}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}
