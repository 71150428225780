import React from "react"
import { fontSize, Typography } from "@intouchhealth/ui-components/typography"
import styled from "@emotion/styled"
import { indent, Mobile } from "@intouchhealth/ui-components/theme"

export const TermsAndConditionsText = () => {
  return (
    <Container>
      <AdaptiveTitleTypography type="h2">
        Patient Call to Friends and Family Software Video Services Terms of
        Service
      </AdaptiveTitleTypography>
      <AdaptiveBodyTypography type="h4">
        <br />
        <p>Last Modified: February 1, 2021</p>
        <p>
          READ CAREFULLY BEFORE USING THE PATIENT CALL TO FRIENDS AND FAMILY
          SOFTWARE VIDEO SERVICES: This Patient Call to Friends and Family
          Software Video Services Terms of Service (&quot;<strong>TOS</strong>
          &quot;) is a binding agreement between you (&quot;<strong>you</strong>
          ,&quot; &quot;
          <strong>your,</strong>&quot; or &ldquo;
          <strong>Authorized User</strong>
          &rdquo;) and Teladoc Health, Inc. (&quot;<strong>Provider</strong>
          ,&quot; &quot;
          <strong>we</strong>,&quot; or &quot;<strong>us</strong>&quot;). This
          TOS governs your access to and use of the Software Video
          Services.&nbsp;
        </p>
        <p>
          THE EFFECTIVE DATE OF THIS AGREEMENT IS WHEN YOU BEGIN USE OF THE
          SOFTWARE VIDEO SERVICES (the &quot;<strong>Effective Date</strong>
          &quot;). BY ACCESSING OR USING THE SOFTWARE VIDEO SERVICES YOU (A)
          ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; AND (B)
          ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS
          TERMS.
        </p>
        <ol>
          <li>
            Definitions.
            <ol type="a">
              <br />
              <li>
                &quot;<strong>Authorized User</strong>&quot; means a person
                under the care or treatment of a Teladoc customer (&ldquo;
                <strong>Customer</strong>&rdquo;) (i) who is provided access by
                Customer to the Software Video Services under the rights granted
                to you pursuant to this TOS and (ii) for whom access to the
                Software Video Services has been purchased hereunder.
              </li>
              <br />
              <li>
                &quot;<strong>Software Video Services</strong>&quot; means the
                video call services provided by Provider under this TOS that
                enable an Authorized User to make video calls to their friends
                and family members while under the care or treatment of
                Customer.
              </li>
              <br />
              <li>
                &quot;<strong>Documentation</strong>&quot; means Provider&apos;s
                documentation relating to the Software Video Services provided
                by Provider to you or Customer either electronically or in hard
                copy form/end user documentation relating to the Software Video
                Services, if applicable.
              </li>
              <br />
              <li>
                &quot;<strong>Provider IP</strong>&quot; means the Software
                Video Services, the Documentation, and all intellectual property
                provided to you or Customer or any other Authorized User in
                connection with the foregoing. For the avoidance of doubt,
                Provider IP includes Aggregated Statistics and any information,
                data, or other content derived from Provider&apos;s monitoring
                of your or Customer&apos;s access to or use of the Software
                Video Services, but does not include Your Data.
              </li>
              <br />
              <li>
                &quot;<strong>Third-Party Products</strong>&quot; means any
                products, content, services, information, websites, or other
                materials that are owned by third parties and are incorporated
                into or accessible through the Software Video Services.
              </li>
              <br />
              <li>
                &quot;<strong>Your Data</strong>&quot; means information, data,
                and other content, in any form or medium, that is submitted,
                posted, or otherwise transmitted by you through the Software
                Video Services.
              </li>
              <br />
            </ol>
          </li>
          <li>
            <u>Access and Use</u>.
            <ol type="a">
              <br />
              <li>
                <u>Provision of Access</u>. Subject to terms and conditions of
                this TOS, Provider hereby grants you a revocable, non-exclusive,
                non-transferable, non-sublicensable, limited right to access and
                use the Software Video Services during the Term solely for your
                personal use in accordance with the terms and conditions herein.
                You agree and acknowledge that use of the Software Video
                Services is not intended for the disclosure of any Customer
                business information or confidential information, and your use
                of the Software Video Services for such purpose is in violation
                of this TOS.&nbsp; &nbsp;Any Protected Health Information
                (&ldquo;PHI&rdquo;) as that term is defined under applicable
                federal and/or state law disclosed by you during your use of the
                Software Video Services is incidental to the Software Video
                Services and entirely at your own discretion.&nbsp;
              </li>
              <br />
              <li>
                <u>Documentation License</u>. Subject to the terms and
                conditions contained in this TOS, Provider hereby grants you a
                non-exclusive, non-sublicensable, non-transferable license for
                you to use the Documentation during the Term solely for your
                personal, non-business, and non-healthcare related purposes in
                connection with use of the Software Video Services.
              </li>
              <br />
              <li>
                <u>Use Restrictions</u>. You shall not, and shall not permit any
                Authorized Users to, use the Software Video Services, any
                software component of the Software Video Services, or
                Documentation for any purposes beyond the scope of the access
                granted in this TOS. You shall not at any time, directly or
                indirectly, and shall not permit any Authorized Users to: (i)
                copy, modify, or create derivative works of the Software Video
                Services, any software component of the Software Video Services,
                or Documentation, in whole or in part; (ii) rent, lease, lend,
                sell, license, sublicense, assign, distribute, publish,
                transfer, or otherwise make available the Software Video
                Services or Documentation except as expressly permitted under
                this TOS; (iii) reverse engineer, disassemble, decompile,
                decode, adapt, or otherwise attempt to derive or gain access to
                any software component of the Software Video Services, in whole
                or in part; (iv) remove any proprietary notices from the
                Software Video Services or Documentation; or (v) use the
                Software Video Services or Documentation in any manner or for
                any purpose that infringes, misappropriates, or otherwise
                violates any intellectual property right or other right of any
                person, or that violates any applicable law, regulation, or
                rule.
              </li>
              <br />
              <li>
                <u>Reservation of Rights</u>. Provider reserves all rights not
                expressly granted to you in this TOS. Except for the limited
                rights and licenses expressly granted under this TOS, nothing in
                this TOS grants, by implication, waiver, estoppel, or otherwise,
                to you or any third party any intellectual property rights or
                other right, title, or interest in or to the Provider IP.
              </li>
              <br />
              <li>
                <u>Suspension</u>. Notwithstanding anything to the contrary in
                this TOS, Provider may temporarily suspend your and any other
                Authorized User&apos;s access to any portion or all of the
                Software Video Services if: (i) Provider reasonably determines
                that (A) there is a threat or attack on any of the Provider IP;
                (B) your or any other Authorized User&apos;s use of the Provider
                IP disrupts or poses a security risk to the Provider IP or to
                any other customer or vendor of Provider; (C) your or any other
                Authorized User is using the Provider IP for harmful, fraudulent
                or illegal activities; (D) you are no longer an Authorized User;
                or (E) Provider&apos;s provision of the Software Video Services
                to you or any other Authorized User is prohibited by applicable
                law; or (ii) any vendor of Provider has suspended or terminated
                Provider&apos;s access to or use of any third-party services or
                products required to enable you to access the Software Video
                Services.
              </li>
              <br />
              <li>
                Provider will have no liability for any damage, liabilities,
                losses, or any other consequences that you or any other
                Authorized User may incur as a result of the unavailability of
                the Software Video Services.
              </li>
              <br />
            </ol>
          </li>
          <br />
          <li>
            <u>Customer Responsibilities</u>.
            <ol type="a">
              <br />
              <li>
                <u>Acceptable Use Policy</u>. The Software Video Services may
                not be used for unlawful, fraudulent, offensive, or obscene
                activity, or for any activity involving the disclosure of
                Customer Confidential Information or business information. The
                Software Video Services are also not intended to be used for the
                transmission of your PHI or other Authorized Users, and any
                transmission of PHI is incidental to the Software Video
                Services.&nbsp; You will comply with all terms and conditions of
                this TOS, all applicable laws, rules, and regulations.
              </li>
              <br />
              <li>
                <u>Account Use</u>. You are responsible and liable for all uses
                of the Software Video Services and Documentation resulting from
                access provided by you, directly or indirectly, whether such
                access or use is permitted by or in violation of this TOS.
                Without limiting the generality of the foregoing, you are
                responsible for your acts and omissions, and any act or omission
                by users that you have provided with unauthorized access to the
                Software Video Services where such acts and omissions would
                constitute a breach of this TOS if taken by you, and such will
                be deemed a breach of this TOS by you.
              </li>
              <br />
              <li>
                <u>Your Data</u>. You hereby grant to Provider a non-exclusive,
                royalty-free, worldwide license to reproduce, distribute, and
                otherwise use and display Your Data and perform all acts with
                respect to Your Data as may be necessary for Provider to provide
                the Software Video Services to you.
              </li>
              <br />
              <li>
                <u>Passwords and Access Credentials</u>. If you create or are
                provided by Customer any access credential and/or passwords, you
                are responsible for keeping your passwords and access
                credentials associated with the Software Video Services
                confidential. You will not sell or transfer them to any other
                person or entity. You will promptly notify us about any
                unauthorized access to your passwords or access credentials.
              </li>
              <br />
            </ol>
          </li>
          <br />
          <li>
            <u>Intellectual Property Ownership; Feedback</u>. As between you and
            us, (a) we own all right, title, and interest, including all
            intellectual property rights, in and to the Software Video Services
            and (b) you own all right, title, and interest, including all
            intellectual property rights, in and to Your Data. If you send or
            transmit any communications or materials to us by mail, email,
            telephone, or otherwise, suggesting or recommending changes to the
            Software Video Services, including without limitation, new features
            or functionality relating thereto, or any comments, questions,
            suggestions, or the like (&quot;<strong>Feedback</strong>&quot;), we
            are free to use such Feedback irrespective of any other obligation
            or limitation between you and us governing such Feedback. All
            Feedback is and will be treated as non-confidential. You hereby
            assign to us on your behalf, all right, title, and interest in, and
            we are free to use, without any attribution or compensation to you
            or any third party, any ideas, know-how, concepts, techniques, or
            other intellectual property rights contained in the Feedback, for
            any purpose whatsoever, although we are not required to use any
            Feedback.
          </li>
          <br />
          <li>
            <u>Limited Warranty and Warranty Disclaimer</u>.
            <ol type="a">
              <br />
              <li>
                THE SOFTWARE VIDEO SERVICES ARE PROVIDED &quot;AS IS&quot; AND
                PROVIDER SPECIFICALLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
                IMPLIED, STATUTORY, OR OTHERWISE. PROVIDER SPECIFICALLY
                DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL
                WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE
                PRACTICE. PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE
                SOFTWARE VIDEO SERVICES, OR ANY PRODUCTS OR RESULTS OF THE USE
                THEREOF, WILL MEET YOUR OR ANY OTHER PERSON&apos;S OR
                ENTITY&apos;S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION,
                ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY OF
                YOUR OR ANY THIRD PARTY&apos;S SOFTWARE, SYSTEM, OR OTHER
                SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL
                CODE, OR ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL
                BE CORRECTED.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <u>Indemnification</u>. You shall indemnify, hold harmless, and, at
            Provider&apos;s option, defend Provider and its officers, directors,
            employees, agents, affiliates, successors, and assigns from and
            against any and all Losses arising from or relating to any
            Third-Party Claim (i)that Your Data, or any use of Your Data in
            accordance with this TOS, infringes or misappropriates such third
            party&apos;s intellectual property rights; or (ii) based on your
            negligence or willful misconduct or use of the Software Video
            Services in a manner not authorized by this TOS.
          </li>
          <br />
          <li>
            <u>Limitations of Liability</u>. IN NO EVENT WILL PROVIDER BE LIABLE
            UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR
            EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, FOR ANY: (a)
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED,
            OR PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR
            LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF
            GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS,
            INTERRUPTION, DELAY OR RECOVERY OF ANY DATA, OR BREACH OF DATA OR
            SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN
            EACH CASE REGARDLESS OF WHETHER PROVIDER WAS ADVISED OF THE
            POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE
            OTHERWISE FORESEEABLE. IN NO EVENT WILL PROVIDER&apos;S AGGREGATE
            LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY
            LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
            (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE EXCEED THE
            TOTAL AMOUNTS PAID TO PROVIDER UNDER THIS AGREEMENT IN THE SIX (12)
            MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM, IF ANY.
          </li>
          <br />
          <li>
            <u>Term and Termination</u>.
            <ol type="a">
              <br />
              <li>
                <u>Term</u>. The term of this TOS begins on the Effective Date
                and continues until you are no longer considered an Authorized
                User, or until this TOS is otherwise terminated pursuant to the
                terms of this TOS.
              </li>
              <br />
              <li>
                <u>Termination</u>. If you fail to comply with any provision of
                this TOS, Provider may terminate this Agreement immediately.
                Upon any termination of this TOS, you must cease any further use
                of the Software Video Services. You agree and acknowledge that
                you are under no obligation to use the Software Video Services,
                and that if at any time you are not happy with the Software
                Video Services, your sole remedy is to cease using the Software
                Video Services.
              </li>
              <br />
              <li>
                <u>Effect of Termination</u>. Upon termination of this TOS, you
                shall immediately discontinue use of the Software Video Services
                and any Provider IP.
              </li>
              <br />
              <li>
                <u>Survival</u>. This Section 8, Sections 3, 4, 5, 6, 7, and 9,
                and any right, obligation, or required performance of the
                parties in this TOS which, by its express terms or nature and
                context is intended to survive termination of this TOS, will
                survive any such termination.
              </li>
              <br />
            </ol>
          </li>
          <br />
          <li>
            <u>Governing Law and Jurisdiction</u>. This agreement is governed by
            and construed in accordance with the internal laws of the State of
            New York without giving effect to any choice or conflict of law
            provision or rule that would require or permit the application of
            the laws of any jurisdiction. Any legal suit, action, or proceeding
            arising out of or related to this his agreement or the rights
            granted hereunder will be instituted exclusively in the federal
            courts of the United States or the courts of the State of New York
            in each case located in the County of Westchester, and each party
            irrevocably submits to the jurisdiction of such courts in any such
            suit, action, or proceeding.
          </li>
        </ol>
      </AdaptiveBodyTypography>
    </Container>
  )
}

const AdaptiveTitleTypography = styled(Typography)`
  display: block;
  text-align: center;
  ${Mobile} {
    font-size: ${fontSize().h3.fontSize};
  }
`

const AdaptiveBodyTypography = styled(Typography)`
  font-size: 1rem;
  line-height: 1.25rem;
  ${Mobile} {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`

const Container = styled.div`
  & ol {
    padding-left: ${indent(2.5)};
  }
`
