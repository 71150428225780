import React, { FC } from "react"
import { Global, css } from "@emotion/core"
import { InjectFont } from "@intouchhealth/ui-components/fonts"
import { T400 } from "@intouchhealth/ui-components/colors"

const FONT_FAMILY = "Montserrat"

export const GlobalStyles: FC = ({ children }) => (
  <>
    <Global styles={globalStyles} />
    <InjectFont family={FONT_FAMILY} />
    {children}
  </>
)

const globalStyles = css`
  * {
    box-sizing: border-box;
  }
  html,
  body {
    height: 100%;
    min-height: 100%;
    font-family: "${FONT_FAMILY}";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;
    overflow-y: hidden;

    @media print {
      overflow-x: auto;
      overflow-y: auto;
      height: auto;
    }
  }
  a {
    color: #${T400};
    text-decoration: none;
    &:hover,
    &:active,
    &:focus,
    &:visited {
      outline: none;
    }
  }
  button {
    &:focus {
      outline: none;
    }
  }
`
