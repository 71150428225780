import React, { useEffect, useState } from "react"
import { Event } from "effector"
import { useStore } from "effector-react"
import {
  AuthorizeResponse,
  devicesAccessIssuesErrorStore,
  PureVideocall,
  setOpenEndCallModalOnLoadInStorage,
  getOpenEndCallModalOnLoadFromStorage,
} from "Videocall"
import { interpolate, translation } from "locales"
import { getTokenFromStorage } from "Guest/Invitation"
import { callerNameStore } from "Guest/Invitation/store"
import {
  useVideocall,
  participants as participantsStore,
} from "@intouchhealth/videocall"
import { sessionCreated } from "@intouchhealth/videocall/participants"
import { leaveVideoCall } from "@intouchhealth/videocall/end-video-call"
import { http } from "Guest/Http"
import { EndCallModal } from "./Controls/EndCallModal"

type Props = {
  videocallId: string
  callEndedPath: string
  leftCallPath: string
  navigationBlocked: Event<void>
}

export const Videocall = ({
  videocallId,
  callEndedPath,
  leftCallPath,
  navigationBlocked,
}: Props) => {
  const callerName = useStore(callerNameStore) || undefined
  const participants = useStore(participantsStore)
  const devicesAccessIssuesError = useStore(devicesAccessIssuesErrorStore)

  const [isEndCallModalOpen, setEndCallModalOpen] = useState(false)
  useEffect(() => {
    const unwatch = navigationBlocked.watch(() => {
      setOpenEndCallModalOnLoadInStorage(true)
      leaveVideoCall(window.location.href)
    })

    return () => unwatch()
  }, [navigationBlocked])
  useEffect(() => {
    if (getOpenEndCallModalOnLoadFromStorage()) {
      setOpenEndCallModalOnLoadInStorage(false)
      setEndCallModalOpen(true)
    }
  }, [])

  const [selfId, setSelfId] = useState<undefined | string>(undefined)
  useEffect(() => {
    void http
      .post<AuthorizeResponse>(`/videocall/${videocallId}/authorize`, {})
      .then(({ participantId }) => {
        setSelfId(participantId)
      })
      .catch(({ statusCode }) => {
        if (statusCode === 404) {
          leaveVideoCall(callEndedPath)
        }
      })
  }, [])

  const [cameraPublished, setCameraPublished] = useState(false)
  useEffect(() => {
    if (!cameraPublished) {
      const self = participants.find((p) => p.id === selfId)
      if (self?.cameraStream) {
        setCameraPublished(true)
      }
    }
  }, [participants, selfId])

  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(false)
  useEffect(() => {
    const minimumTime = 2000
    const timeoutId = window.setTimeout(
      () => setMinimumTimeElapsed(true),
      minimumTime
    )

    return () => window.clearTimeout(timeoutId)
  }, [])

  const getShowInterstitial = () => !(cameraPublished && minimumTimeElapsed)

  useVideocall({
    videocallId,
    authToken: getTokenFromStorage(),
    enablePingPong: false,
    reauthorizeVideocallAnalytics: false,
  })

  sessionCreated.watch(({ session }) => {
    session.on("signal:endCall", () => {
      leaveVideoCall(callEndedPath)
    })
  })

  return (
    <PureVideocall
      showInterstitial={getShowInterstitial()}
      interstitialMessage={
        callerName
          ? interpolate(translation.guest.videocall.connectingToName, {
              name: callerName,
            })
          : translation.guest.videocall.connecting
      }
      showMainVideoMessageSpinner={false}
      mainVideoMessage={translation.guest.videocall.hostDisconnected}
      devicesAccessIssuesError={devicesAccessIssuesError}
      openEndCallModal={() => setEndCallModalOpen(true)}
      endCallModal={
        <EndCallModal
          onButtonClick={() => leaveVideoCall(leftCallPath)}
          isOpen={isEndCallModalOpen}
          onClose={() => setEndCallModalOpen(false)}
        />
      }
      controlsExitLabel={translation.videocall.controlLabel.leaveCall}
      participants={participants}
      selfId={selfId}
    />
  )
}
