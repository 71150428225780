import React from "react"
import styled from "@emotion/styled"
import { indent } from "@intouchhealth/ui-components/theme"
import { translation } from "locales"
import { Button } from "@intouchhealth/ui-components/button"
import { PureInput } from "@intouchhealth/ui-components/input"
import { NewUserIcon } from "@intouchhealth/ui-components/icons"
import { N500 } from "@intouchhealth/ui-components/colors"
import { NewField } from "@intouchhealth/ui-components/FormElements/Field"
import { MomChildImage } from "components/Illustrations"
import { TitleText } from "components/typography"

type Props = {
  onContinue: () => void
  name: string
  onNameChange: (name: string) => void
}

export const PureEnterName = (props: Props) => {
  const { onContinue, name, onNameChange } = props
  const isContinueButtonEnabled = () => name.trim().length > 0

  return (
    <MainContainer>
      <ImageContainer>
        <Image />
      </ImageContainer>
      <TextContainer>
        <TitleText>{translation.patient.enterName.title}</TitleText>
      </TextContainer>
      <InputFieldsContainer>
        <NewField
          label={translation.patient.enterName.inputLabels.yourName}
          labelFontType="h5"
        />
        <PureInput
          value={name}
          onChange={onNameChange}
          icon={<NewUserIcon fill={N500} />}
          iconTabIndex={-1}
          iconAriaHidden
          maxLength={70}
          dataTarget="patient-name-input"
        />
      </InputFieldsContainer>
      <ButtonContainer>
        <Button
          size="m"
          themeName="whitelabel"
          onClick={onContinue}
          disabled={!isContinueButtonEnabled()}
          data-target="continue-button"
        >
          {translation.buttonLabels.continue}
        </Button>
      </ButtonContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${indent(4)};
`
const Image = styled(MomChildImage)`
  height: 224px;
  width: 224px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${indent(5)};
`

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${indent(3)};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${indent(8)};
`
