import React from "react"

export { default as translation } from "./translation.en_US.json"

const reducer = (translation: string, [key, value]: string[]) =>
  translation.replace(new RegExp(`{{\\s*${key}\\s*}}`, "g"), value)

export const interpolate = (
  translation: string,
  replacements: {
    [key: string]: string
  }
) => Object.entries(replacements).reduce(reducer, translation)

export const Trans = ({ translation }: { translation: string }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: translation,
      }}
    />
  )
}
