import { init, withScope, Severity, captureException } from "@sentry/browser"

const isEnabled = () => !!window.environment

export const initSentry = () =>
  isEnabled() &&
  init({
    dsn: window.config.SENTRY_DSN,
    environment: window.environment,
  })

export function error(exception: unknown) {
  withScope((scope) => {
    scope.setLevel(Severity.Error)
    captureException(exception)
  })
}
