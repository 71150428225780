import React from "react"
import { indent } from "@intouchhealth/ui-components/theme"
import styled from "@emotion/styled"
import { ModalWindow } from "@intouchhealth/ui-components/modal"
import { Typography } from "@intouchhealth/ui-components/typography"
import { translation } from "locales"
import { Button } from "@intouchhealth/ui-components/button"
import { HyperlinkButton } from "components/Hyperlink"
import { CloseModalButton } from "./CloseModalButton"

export const EndCallModalMobileBreakpoint = "@media (max-width: 400px)"

type Props = {
  onButtonClick: () => void
  headerText: string
  bodyText?: string
  buttonText: string
  icon: JSX.Element
  isOpen: boolean
  onClose: () => void
}

export const EndCallModal = (props: Props) => {
  const customModalStyle = {
    content: { padding: "12px 12px 18px" },
  }
  const {
    onButtonClick,
    headerText,
    bodyText,
    buttonText,
    icon,
    isOpen,
    onClose,
  } = props
  return (
    <StyledModalWindow
      style={customModalStyle}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ModalWrapper data-target="end-call-modal-wrapper">
        <TitleAndCloseWrapper>
          {icon}
          <ModalTitle fontWeight="bold" type="h4">
            {headerText}
          </ModalTitle>
          <CloseModalButton onClick={onClose} />
        </TitleAndCloseWrapper>
        {bodyText && <ModalBody type="paragraph">{bodyText}</ModalBody>}
        <ButtonsWrapper>
          <Button
            onClick={onButtonClick}
            data-leave-call-button
            data-target="modal-leave-call-button"
            themeName="danger"
            size="s"
          >
            {buttonText}
          </Button>
          <StyledHyperlink
            onClick={onClose}
            buttonContentDataTarget="cancel-button-text"
            dataTarget="modal-cancel-button"
          >
            {translation.buttonLabels.cancel}
          </StyledHyperlink>
        </ButtonsWrapper>
      </ModalWrapper>
    </StyledModalWindow>
  )
}

const StyledModalWindow = styled(ModalWindow)`
  ${EndCallModalMobileBreakpoint} {
    width: 307px;
  }
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 380px;
  max-width: 100%;
  ${EndCallModalMobileBreakpoint} {
    height: 100%;
    padding: ${indent(0.5)};
  }
`

const TitleAndCloseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ModalTitle = styled(Typography)`
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  padding-left: ${indent(1.5)};
`

const ModalBody = styled(Typography)`
  padding-top: ${indent(1.5)};
  padding-left: ${indent(5.5)};
  padding-right: ${indent(2)};
  ${EndCallModalMobileBreakpoint} {
    padding-left: 2px;
    padding-right: 0px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  padding-left: ${indent(5.5)};
  padding-top: ${indent(2.5)};
  ${EndCallModalMobileBreakpoint} {
    padding-left: 0px;
    flex-basis: 50%;
  }
`

const StyledHyperlink = styled(HyperlinkButton)`
  margin-left: ${indent(1)};
`
