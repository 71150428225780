import React from "react"
import styled from "@emotion/styled"
import { ModalWindow } from "@intouchhealth/ui-components/modal"
import { T400, N800 } from "@intouchhealth/ui-components/colors"
import { SettingsIcon } from "@intouchhealth/ui-components/icons"
import { indent } from "@intouchhealth/ui-components/theme"
import { Typography } from "@intouchhealth/ui-components/typography"
import { DeviceSelector } from "@intouchhealth/iwa-videocall-ui/devices"
import {
  Device,
  AudioDevice,
  VideoDevice,
} from "@intouchhealth/videocall/devices/device"
import { translation } from "locales"
import { CloseModalButton } from "./CloseModalButton"

type Props = {
  isOpen: boolean
  onClose: () => void
  changeVideo: (video: VideoDevice) => void
  changeAudio: (audio: AudioDevice) => void
  audioInputDevices: Device[]
  videoInputDevices: Device[]
  selectedAudioInputDevice?: Device
  selectedVideoInputDevice?: Device
}

export const DevicesModal = ({
  isOpen,
  onClose,
  audioInputDevices,
  videoInputDevices,
  selectedAudioInputDevice,
  selectedVideoInputDevice,
  changeVideo,
  changeAudio,
}: Props) => {
  return (
    <StyledModalWindow
      onRequestClose={onClose}
      isOpen={isOpen}
      data={{ target: "devices-settings-modal" }}
    >
      <section>
        <HeaderContainer>
          <IconAndTitleContainer>
            <HeaderSettingsIcon iconSize="l" fill={T400} />
            <Typography type="h4" fontWeight="bold">
              {translation.videocall.devicesModal.settings}
            </Typography>
          </IconAndTitleContainer>
          <CloseModalButton onClick={onClose} />
        </HeaderContainer>
        <SelectBoxContainer>
          <Label>
            <Typography type="h6" fontColor={N800}>
              {translation.videocall.devicesModal.camera}
            </Typography>
          </Label>
          <DeviceSelector
            newUi
            kind="videoInput"
            placeholder={translation.videocall.devicesModal.selectCamera}
            getDefaultLabel={getDefaultCameraLabel}
            devices={videoInputDevices}
            selectedDevice={selectedVideoInputDevice || null}
            changeDevice={changeVideo}
          />
        </SelectBoxContainer>
        <SelectBoxContainer>
          <Label>
            <Typography type="h6" fontColor={N800}>
              {translation.videocall.devicesModal.mic}
            </Typography>
          </Label>
          <DeviceSelector
            newUi
            kind="audioInput"
            placeholder={translation.videocall.devicesModal.selectMic}
            getDefaultLabel={getDefaultMicrophoneLabel}
            devices={audioInputDevices}
            selectedDevice={selectedAudioInputDevice || null}
            changeDevice={changeAudio}
          />
        </SelectBoxContainer>
      </section>
    </StyledModalWindow>
  )
}

const getDefaultCameraLabel = (number: number) =>
  `${translation.videocall.devicesModal.camera} #${number}`
const getDefaultMicrophoneLabel = (number: number) =>
  `${translation.videocall.devicesModal.mic} #${number}`

const StyledModalWindow = styled(ModalWindow)`
  padding: 12px !important;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const HeaderSettingsIcon = styled(SettingsIcon)`
  margin-right: 19px;
`

const IconAndTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SelectBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  > * {
    margin-top: 3px !important;
    margin-bottom: 3px;
  }
`
const Label = styled.div`
  margin-left: ${indent(6)};
  flex-grow: 1;
  flex-basis: 0;
`
