import React from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { PatientRoutes, ActivationCheckRoute } from "Patient/routes"
import { GuestRoutes, InvitationParseRoute } from "Guest/routes"
import { PageNotFound } from "components/PageNotFound"
import { Layout } from "components/Layout"
import { UnsupportedBrowser } from "components/UnsupportedBrowser"
import { BrowserCheck } from "Init/browser"
import { toUnsupported } from "Router"
import { TermsAndConditions } from "components/TermsAndConditions"

export const Routes = () => {
  const history = useHistory()
  const onBack = () => history.goBack()
  return (
    <BrowserCheck onUnsupported={() => history.replace(toUnsupported())}>
      <Switch>
        <Route
          exact
          path={[`/activate`, `/activate/:activationCode`]}
          component={ActivationCheckRoute}
        />
        <Route exact path="/g/:accessCode" component={InvitationParseRoute} />
        <Route path="/patient" component={PatientRoutes} />
        <Route path="/guest" component={GuestRoutes} />
        <Route
          exact
          path="/unsupported"
          render={() => (
            <Layout>
              <UnsupportedBrowser />
            </Layout>
          )}
        />
        <Route
          exact
          path="/terms-and-conditions"
          render={() => (
            <Layout onBack={onBack} fullHeightBody>
              <TermsAndConditions onAccept={onBack} />
            </Layout>
          )}
        />
        <Route
          render={() => (
            <Layout>
              <PageNotFound />
            </Layout>
          )}
        />
      </Switch>
    </BrowserCheck>
  )
}
