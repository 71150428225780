import React from "react"
import styled from "@emotion/styled"
import { Button } from "@intouchhealth/ui-components/button"

type Props = {
  className?: string
  onClick: () => void
  buttonText: string
  dataTarget?: string
}
export const LargePositiveButton = (props: Props) => {
  return (
    <ButtonContainer className={props.className}>
      <Button
        data-target={props.dataTarget}
        size="xl"
        themeName="positive"
        onClick={props.onClick}
      >
        {props.buttonText}
      </Button>
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  width: 400px;
  max-width: 100%;
  > button {
    width: 100%;
  }
`
