import React from "react"
import { useCameraAndMicrophone } from "@intouchhealth/videocall"
import { idle } from "@intouchhealth/iwa-videocall-ui/components/idle"
import { PureControls } from "./PureControls"

const IdledControls = idle(PureControls, 2000)

type Props = {
  className?: string
  isDevicesSettingsModalShown: boolean
  openDevicesSettingsModal: () => void
  openEndCallModal: () => void
  controlsExitLabel: string
}

export const Controls = ({
  className,
  isDevicesSettingsModalShown,
  openDevicesSettingsModal,
  openEndCallModal,
  controlsExitLabel,
}: Props) => {
  return (
    <IdledControls
      className={className}
      openEndCallModal={openEndCallModal}
      isDevicesSettingsModalShown={isDevicesSettingsModalShown}
      openDevicesSettingsModal={openDevicesSettingsModal}
      controlsExitLabel={controlsExitLabel}
      {...useCameraAndMicrophone()}
    />
  )
}
