import React from "react"
import { translation } from "locales"
import { AuthError } from "Auth/Error"

export const Unauthorized = () => (
  <AuthError
    title={translation.patient.error.unauthorized.title}
    body1={translation.patient.error.unauthorized.body}
    support={translation.patient.error.support}
  />
)
