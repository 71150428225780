import React from "react"
import styled from "@emotion/styled"
import { BackButton } from "./BackButton"
import { Logo } from "./Logo"
import { Lock } from "./Lock"

type Props = {
  className?: string
  onBack?: () => void
}

export const FormsHeader = (props: Props) => {
  const { className, onBack } = props
  return (
    <MainContainer className={className}>
      <BackButtonContainer>
        {onBack && <BackButton onClick={onBack} />}
      </BackButtonContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <LockContainer>
        <Lock />
      </LockContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > * {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
`
const BackButtonContainer = styled.div`
  justify-content: flex-start;
`

const LogoContainer = styled.div`
  justify-content: center;
`

const LockContainer = styled.div`
  justify-content: flex-end;
`
