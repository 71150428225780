import React from "react"
import { translation } from "locales"
import { PureStart } from "components/Start"

type Props = {
  onStart: () => void
  onViewTermsAndConditions: () => void
}

export const Start = (props: Props) => {
  const { onStart, onViewTermsAndConditions } = props
  return (
    <PureStart
      onStart={onStart}
      onViewTermsAndConditions={onViewTermsAndConditions}
      titleText={translation.patient.start.title}
      bodyText={translation.patient.start.body}
      buttonText={translation.buttonLabels.start}
    />
  )
}
