import React from "react"
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom"
import { AuthenticatedContext } from "Auth"
import { Layout } from "components/Layout"
import { PageNotFound } from "components/PageNotFound"
import { CheckStarted, MarkStarted } from "./FormsStarted"
import { BlockNavigation } from "components/BlockNavigation"
import {
  toActivation,
  toActivationError,
  toEnded,
  toEnterName,
  toInvite,
  toReactivation,
  toReview,
  toStart,
  toUnauthorized,
  toVideocall,
} from "./Router"
import {
  ActivationCheck,
  ActivationError,
  getTokenFromStorage,
  Unauthorized,
} from "./Activation"
import { Start } from "./Start"
import { EnterName } from "./EnterName"
import { Invite } from "./Invite"
import { Review } from "./Review"
import { Videocall } from "./Videocall"
import { Ended } from "./Ended"
import { toTermsAndConditions } from "Router"

export const PatientRoutes = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/activation-error`}
        component={() => (
          <Layout>
            <ActivationError />
          </Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/unauthorized`}
        render={() => (
          <Layout>
            <Unauthorized />
          </Layout>
        )}
      />
      <Route component={AuthenticatedRoutes} />
    </Switch>
  )
}

const AuthenticatedRoutes = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const onBack = () => history.goBack()
  return (
    <AuthenticatedContext
      token={getTokenFromStorage()}
      onError={() => history.replace(toUnauthorized())}
    >
      <Switch>
        <Route
          exact
          path={`${match.url}/reactivate`}
          render={() => (
            <MarkStarted>
              <ActivationCheck
                onContinue={() => history.replace(toEnterName())}
                onError={() => history.replace(toActivationError())}
              />
            </MarkStarted>
          )}
        />
        <Route
          exact
          path={`${match.url}/start`}
          render={() => (
            <MarkStarted>
              <Layout>
                <Start
                  onStart={() => history.push(toEnterName())}
                  onViewTermsAndConditions={() =>
                    history.push(toTermsAndConditions())
                  }
                />
              </Layout>
            </MarkStarted>
          )}
        />
        <Route
          exact
          path={`${match.url}/enter-name`}
          render={() => (
            <CheckStarted onNotStarted={() => history.replace(toStart())}>
              <Layout onBack={onBack}>
                <EnterName onContinue={() => history.push(toInvite())} />
              </Layout>
            </CheckStarted>
          )}
        />
        <Route
          exact
          path={`${match.url}/invite`}
          render={() => (
            <CheckStarted onNotStarted={() => history.replace(toStart())}>
              <Layout onBack={onBack}>
                <Invite onContinue={() => history.push(toReview())} />
              </Layout>
            </CheckStarted>
          )}
        />
        <Route
          exact
          path={`${match.url}/review`}
          render={() => (
            <CheckStarted onNotStarted={() => history.replace(toStart())}>
              <Layout onBack={onBack}>
                <Review
                  onEditPatient={() => history.push(toEnterName())}
                  onEditFnf={() => history.push(toInvite())}
                  onContinue={(videocallId) =>
                    history.push(toVideocall(videocallId))
                  }
                />
              </Layout>
            </CheckStarted>
          )}
        />
        <Route
          exact
          path={`${match.url}/videocall/:videocallId`}
          render={({ match }: RouteComponentProps<{ videocallId: string }>) => (
            <MarkStarted>
              <BlockNavigation>
                {(navigationBlocked) => (
                  <Videocall
                    videocallId={match.params.videocallId}
                    callEndedPath={toEnded()}
                    navigationBlocked={navigationBlocked}
                  />
                )}
              </BlockNavigation>
            </MarkStarted>
          )}
        />
        <Route
          exact
          path={`${match.url}/ended`}
          render={() => (
            <Layout>
              <Ended onNewCall={() => history.push(toReactivation())} />
            </Layout>
          )}
        />
        <Route
          render={() => (
            <Layout>
              <PageNotFound />
            </Layout>
          )}
        />
      </Switch>
    </AuthenticatedContext>
  )
}

export const ActivationCheckRoute = () => {
  const history = useHistory()
  const match = useRouteMatch<{ activationCode: string }>()
  return (
    <ActivationCheck
      activationCode={match.params.activationCode}
      removeActivationCode={() => history.replace(toActivation())}
      onContinue={() => history.replace(toStart())}
      onError={() => history.replace(toActivationError())}
    />
  )
}
