import React from "react"
import { FootnoteText } from "components/typography"
import { HyperlinkButton } from "components/Hyperlink"
import { translation } from "locales"
import styled from "@emotion/styled"
import { indent } from "@intouchhealth/ui-components/theme"
import { fontSize } from "@intouchhealth/ui-components/typography"
import { SmallMobile } from "components/theme"

type Props = {
  className?: string
  onClick: () => void
  dataTarget?: string
}

export const TermsAndConditionsLink = (props: Props) => {
  const { className, onClick, dataTarget } = props
  return (
    <TermsAndConditionsLinkContainer className={className}>
      <FootnoteText>{translation.legal.termsAndConditions.line1}</FootnoteText>
      <StyledHyperLink
        dataTarget={dataTarget}
        onClick={onClick}
        type="h5"
        fontWeight="medium"
        inline
      >
        {translation.legal.termsAndConditions.line2}
      </StyledHyperLink>
    </TermsAndConditionsLinkContainer>
  )
}

const TermsAndConditionsLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledHyperLink = styled(HyperlinkButton)`
  margin-top: ${indent(1)};
  ${SmallMobile} {
    font-size: ${fontSize().h6.fontSize};
  }
`
