import React from "react"
import { translation } from "locales"
import { PhoneIcon } from "@intouchhealth/ui-components/icons"
import { X400 } from "@intouchhealth/ui-components/colors"
import {
  EndCallModal as SharedEndCallModal,
  EndCallModalMobileBreakpoint,
} from "Videocall/Controls/EndCallModal"
import styled from "@emotion/styled"
import { indent } from "@intouchhealth/ui-components/theme"

type Props = {
  onButtonClick: () => void
  isOpen: boolean
  onClose: () => void
}

export const EndCallModal = (props: Props) => {
  const { onButtonClick, isOpen, onClose } = props
  return (
    <SharedEndCallModal
      headerText={translation.guest.videocall.endCallModal.header}
      buttonText={translation.buttonLabels.leave}
      icon={<StyledPhoneIcon iconSize="l" fill={X400} />}
      onButtonClick={onButtonClick}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

const StyledPhoneIcon = styled(PhoneIcon)`
  transform: rotate(135deg);
  ${EndCallModalMobileBreakpoint} {
    margin-right: ${indent(0.5)};
  }
`
