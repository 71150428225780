import { http } from "Patient/Http"
import React, { useEffect } from "react"
import { ActivationResponse } from "./Model"
import {
  getCodeFromStorage,
  setCodeInStorage,
  setTokenInStorage,
} from "./SessionStorage"

type Props = {
  activationCode?: string
  removeActivationCode?: () => void
  onContinue: () => void
  onError: () => void
}

export const ActivationCheck = (props: Props) => {
  const { activationCode, removeActivationCode, onContinue, onError } = props
  useEffect(() => {
    const code = activationCode || getCodeFromStorage()
    removeActivationCode?.()
    if (!code) {
      onError()
      return
    }
    http
      .get<ActivationResponse>(`/activation/code/${code}`)
      .then((response) => {
        const { token } = response
        setTokenInStorage(token)
        setCodeInStorage(code)

        onContinue()
      })
      .catch(() => {
        onError()
      })
  }, [])
  return <></>
}
