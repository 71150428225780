import React, { ReactNode, useEffect } from "react"

type Props = {
  token?: string
  children: ReactNode
  onError: () => void
}

export const AuthenticatedContext = ({ token, children, onError }: Props) => {
  useEffect(() => {
    if (!token) onError()
  }, [token])
  return token ? <>{children}</> : null
}
