import React from "react"
import { useStore } from "effector-react"
import { setInviteDetails, inviteDetailsStore } from "./store"
import { InviteDetails } from "./Model"
import { PureInvite } from "./PureInvite"
import { sanitizeName } from "Patient/ValidationSanitization/name"

type Props = {
  onContinue: () => void
}

export const Invite = (props: Props) => {
  const { onContinue } = props

  const savedInviteDetails = useStore(inviteDetailsStore)

  const saveInviteDetailsAndContinue = (inviteDetails: InviteDetails) => {
    const name = sanitizeName(inviteDetails.name)
    const sanitizedInviteDetails = { ...inviteDetails, name }
    setInviteDetails(sanitizedInviteDetails)
    onContinue()
  }

  return (
    <PureInvite
      onContinue={saveInviteDetailsAndContinue}
      initialInviteDetails={savedInviteDetails}
    />
  )
}
