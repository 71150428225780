import React, { FC, ReactNode, useEffect } from "react"
import Bowser from "bowser"
import { toUnsupported } from "Router"
import { useLocation } from "react-router"
import { setGuestInviteLinkInStorage } from "./SessionStorage"
import { setPageFavicon } from "@intouchhealth/browser/browserTabAppearance"
import favicon from "./img/favicon-32x32.png"
import AppleTouchIcon from "./img/apple-touch-icon-180x180.png"

const setAppleTouchIcon = (icon: string) => {
  const headElement = document.getElementsByTagName("head")[0]
  const newIconElement = document.createElement("link")
  newIconElement.rel = "apple-touch-icon"
  newIconElement.href = icon
  headElement.appendChild(newIconElement)
}

setPageFavicon(favicon)
setAppleTouchIcon(AppleTouchIcon)

export type BrowserName = "safari" | "firefox" | "chrome"

export const isBrowserSupportedOnCurrentOs = (browserName: BrowserName) => {
  const browser = getBrowser()
  const osName = browser.getOSName(true)
  return isBrowserSupportedForOs(osName, browserName)
}

export const isBrowserSupportedForOs = (
  osName: string,
  browserName: string
) => {
  switch (osName) {
    case "ios":
      return isSupportedIosBrowser(browserName)
    case "macos":
      return isSupportedMacBrowser(browserName)
    case "windows":
      return isSupportedWindowsBrowser(browserName)
    case "android":
      return isSupportedAndroidBrowser(browserName)
    default:
      return false
  }
}

const getBrowser = (userAgent = window.navigator.userAgent) =>
  Bowser.getParser(userAgent)

const isSupportedIosBrowser = (browserName: string) => browserName === "safari"

const isSupportedAndroidBrowser = (browserName: string) =>
  browserName === "chrome"

const isSupportedWindowsBrowser = (browserName: string) =>
  browserName === "firefox" || browserName === "chrome"

const isSupportedMacBrowser = (browserName: string) =>
  browserName === "safari" || browserName === "chrome"

const isCurrentBrowserSupported = () => {
  const browser = getBrowser()
  const osName = browser.getOSName(true)
  const browserName = browser.getBrowserName(true)

  return isBrowserSupportedForOs(osName, browserName)
}

type Props = {
  children: ReactNode
  onUnsupported: () => void
}

export const BrowserCheck: FC<Props> = (props: Props) => {
  const { children, onUnsupported } = props
  const path = useLocation().pathname
  if (path.startsWith("/g/")) {
    setGuestInviteLinkInStorage(location.href)
  }
  const executeRedirect =
    !isCurrentBrowserSupported() && path !== toUnsupported()

  useEffect(() => {
    if (executeRedirect) {
      onUnsupported()
    }
  })

  return executeRedirect ? null : <>{children}</>
}
