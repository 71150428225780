import React from "react"
import styled from "@emotion/styled"
import { Button } from "@intouchhealth/ui-components/button"
import { ArrowIcon } from "@intouchhealth/ui-components/icons"
import { translation } from "locales"

const LeftArrowIcon = styled(ArrowIcon)`
  transform: scaleX(-1);
`

type Props = {
  onClick: () => void
}

export const BackButton = (props: Props) => (
  <Button
    size="xs"
    rounded={true}
    icon={<LeftArrowIcon />}
    iconPosition="left"
    onClick={props.onClick}
    ariaLabel={translation.buttonLabels.back}
    data-target="back-button"
  >
    <TextHiddenForSmallScreens>
      {translation.buttonLabels.back}
    </TextHiddenForSmallScreens>
  </Button>
)

const TextHiddenForSmallScreens = styled.span`
  @media (max-width: 425px) {
    display: none;
  }
`
