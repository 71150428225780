import React from "react"
import { DevicesAccessIssuesModal as Modal } from "@intouchhealth/iwa-videocall-ui/components/modals"
import { translation, Trans } from "locales"

function getDevicesAccessIssuesMessages() {
  return {
    selectAllowWhenPromptedMessage: {
      title:
        translation.videocall.devicesAccessIssuesMessages
          .selectAllowWhenPromptedMessage.title,
      message1:
        translation.videocall.devicesAccessIssuesMessages
          .selectAllowWhenPromptedMessage.message1,
      message2:
        translation.videocall.devicesAccessIssuesMessages
          .selectAllowWhenPromptedMessage.message2,
      reloadText:
        translation.videocall.devicesAccessIssuesMessages
          .selectAllowWhenPromptedMessage.reloadText,
    },
    checkSettingsAndPermissionsMessage: {
      title:
        translation.videocall.devicesAccessIssuesMessages
          .checkSettingsAndPermissionsMessage.title,
      message1:
        translation.videocall.devicesAccessIssuesMessages
          .checkSettingsAndPermissionsMessage.message1,
      message2:
        translation.videocall.devicesAccessIssuesMessages
          .checkSettingsAndPermissionsMessage.message2,
      item1: (
        <Trans
          translation={
            translation.videocall.devicesAccessIssuesMessages
              .checkSettingsAndPermissionsMessage.item1
          }
        />
      ),
      item2: (
        <Trans
          translation={
            translation.videocall.devicesAccessIssuesMessages
              .checkSettingsAndPermissionsMessage.item2
          }
        />
      ),
      item3: (
        <Trans
          translation={
            translation.videocall.devicesAccessIssuesMessages
              .checkSettingsAndPermissionsMessage.item3
          }
        />
      ),
      item4:
        translation.videocall.devicesAccessIssuesMessages
          .checkSettingsAndPermissionsMessage.item4,
      reloadText:
        translation.videocall.devicesAccessIssuesMessages
          .checkSettingsAndPermissionsMessage.reloadText,
    },
    closeOthersAppsAndTabsMessage: {
      title:
        translation.videocall.devicesAccessIssuesMessages
          .closeOthersAppsAndTabsMessage.title,
      message1:
        translation.videocall.devicesAccessIssuesMessages
          .closeOthersAppsAndTabsMessage.message1,
      message2:
        translation.videocall.devicesAccessIssuesMessages
          .closeOthersAppsAndTabsMessage.message2,
      item1:
        translation.videocall.devicesAccessIssuesMessages
          .closeOthersAppsAndTabsMessage.item1,
      item2:
        translation.videocall.devicesAccessIssuesMessages
          .closeOthersAppsAndTabsMessage.item2,
      item3:
        translation.videocall.devicesAccessIssuesMessages
          .closeOthersAppsAndTabsMessage.item3,
      reloadText:
        translation.videocall.devicesAccessIssuesMessages
          .closeOthersAppsAndTabsMessage.reloadText,
    },
  }
}

type Props = {
  className?: string
  isOpen: boolean
  errorName: string
}

export const DevicesAccessIssuesModal = (props: Props) => {
  const { className, isOpen, errorName } = props
  const messages = getDevicesAccessIssuesMessages()
  return (
    <Modal
      className={className}
      isOpen={isOpen}
      errorName={errorName}
      messages={messages}
    />
  )
}
