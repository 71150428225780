import { createEvent, createStore } from "effector"

export const invitesSent = createEvent()

const timeElapsed = createEvent()

let timeoutId: number | undefined
invitesSent.watch(() => {
  window.clearTimeout(timeoutId)
  timeoutId = window.setTimeout(() => timeElapsed(), 15000)
})

export const invitesSentRecentlyStore = createStore(false)
  .on(invitesSent, () => true)
  .on(timeElapsed, () => false)
