import React from "react"
import styled from "@emotion/styled"
import { FormsHeader } from "./Header"
import { SmallMobile, useLayoutViewportHeight } from "components/theme"
import { indent } from "@intouchhealth/ui-components/theme"

type Props = {
  onBack?: () => void
  children?: React.ReactNode
  fullHeightBody?: boolean
}

export const Layout = (props: Props) => {
  const { onBack, children, fullHeightBody } = props
  const viewportHeight = `${useLayoutViewportHeight()}px`

  return (
    <Container noBottomPadding={fullHeightBody}>
      <Content noBottomPadding={fullHeightBody}>
        <Header onBack={onBack} />
        <Body viewportHeight={viewportHeight} fullHeight={fullHeightBody}>
          {children}
        </Body>
      </Content>
    </Container>
  )
}

const headerHeight = "60px"
const containerPadding = indent(4)
const smallMobileHeaderHeight = "53px"
const smallMobileContainerPadding = indent(2)

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  ${({ noBottomPadding }: { noBottomPadding?: boolean }) =>
    `padding: ${
      noBottomPadding
        ? `${containerPadding} ${containerPadding} 0px`
        : `${containerPadding}`
    };
    ${SmallMobile} {
      padding: ${
        noBottomPadding
          ? `${smallMobileContainerPadding} ${smallMobileContainerPadding} 0px`
          : `${smallMobileContainerPadding}`
      };
    }`}
`

const Content = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin: 0 auto;
  ${({ noBottomPadding }: { noBottomPadding?: boolean }) =>
    `padding-bottom: ${noBottomPadding ? "0px" : `${headerHeight}`};
    ${SmallMobile} {
      padding-bottom: ${noBottomPadding ? "0px" : `${smallMobileHeaderHeight}`};
    }`}
`

const Header = styled(FormsHeader)`
  flex-shrink: 0;
  height: ${headerHeight};
  ${SmallMobile} {
    height: ${smallMobileHeaderHeight};
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({
    viewportHeight,
    fullHeight,
  }: {
    viewportHeight: string
    fullHeight?: boolean
  }) =>
    fullHeight
      ? `
          height: calc(${viewportHeight} - ${headerHeight} - ${containerPadding});
          ${SmallMobile} {
            height: calc(${viewportHeight} - ${smallMobileHeaderHeight} - ${smallMobileContainerPadding});
          }
        `
      : "flex-grow: 1;"}
`
