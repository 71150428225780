import React, { useState } from "react"
import { translation } from "locales"
import styled from "@emotion/styled"
import { Typography } from "@intouchhealth/ui-components/typography"
import { indent } from "@intouchhealth/ui-components/theme"
import { CurrentOsSupportedBrowsers } from "./CurrentOsSupportedBrowsers"
import { SupportedBrowserGrid } from "./SupportedBrowserGrid"
import { getGuestInviteLinkFromStorage } from "Init/SessionStorage"
import { BodyText, TitleText } from "components/typography"
import { HyperlinkButton } from "components/Hyperlink"
import { CopyLinkButton } from "components/CopyLinkButton"
import { UnsupportedBrowserImage } from "components/Illustrations"

export const UnsupportedBrowser = () => {
  const guestInviteLink = getGuestInviteLinkFromStorage()
  const [showBrowserGrid, setShowBrowserGrid] = useState(false)

  return (
    <MainContainer>
      <UnsupportedBrowserImage />
      <TextContainer>
        <TitleText>{translation.unsupportedBrowser.title}</TitleText>
        <StyledBodyText>
          <div>{translation.unsupportedBrowser.body1}</div>
          <div>{translation.unsupportedBrowser.body2}</div>
        </StyledBodyText>
      </TextContainer>
      {guestInviteLink && (
        <>
          <Spacer numIndents={3} />
          <CopyLinkButton link={guestInviteLink} />
        </>
      )}
      <Spacer numIndents={6} />
      {!showBrowserGrid && (
        <>
          <CurrentOsSupportedBrowsers />
          <StyledHyperLink
            onClick={() => {
              setShowBrowserGrid(true)
            }}
          >
            <Typography type="h4" fontWeight="bold" fontColor="inherit">
              {translation.unsupportedBrowser.supportedBrowserLink}
            </Typography>
          </StyledHyperLink>
        </>
      )}
      {showBrowserGrid && <SupportedBrowserGrid />}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Spacer = styled.div`
  ${({ numIndents }: { numIndents: number }) =>
    `height: ${indent(numIndents)};`}
`

const StyledBodyText = styled(BodyText)`
  max-width: 600px;
`
const StyledHyperLink = styled(HyperlinkButton)`
  margin-top: ${indent(5)};
`
