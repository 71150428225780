import React from "react"
import styled from "@emotion/styled"
import { N1000 } from "@intouchhealth/ui-components/colors"
import { Participant } from "@intouchhealth/videocall/participants"
import { Overlay } from "./Overlay"
import { InCall } from "./InCall"
import { DevicesAccessIssuesModal } from "./DevicesAccessIssues"

type Props = {
  showInterstitial: boolean
  interstitialMessage: string
  showMainVideoMessageSpinner: boolean
  mainVideoMessage: string
  devicesAccessIssuesError: string
  endCallModal: JSX.Element
  openEndCallModal: () => void
  controlsExitLabel: string
  participants: Participant[]
  selfId?: string
}

export const PureVideocall = (props: Props) => {
  const {
    showInterstitial,
    interstitialMessage,
    showMainVideoMessageSpinner,
    mainVideoMessage,
    devicesAccessIssuesError,
    endCallModal,
    openEndCallModal,
    controlsExitLabel,
    participants,
    selfId,
  } = props
  return (
    <Container>
      <DevicesAccessIssuesModal
        isOpen={!!devicesAccessIssuesError}
        errorName={devicesAccessIssuesError}
      />
      {showInterstitial ? (
        <Overlay showSpinner message={interstitialMessage} />
      ) : (
        <InCall
          participants={participants}
          selfId={selfId}
          devicesAccessIssuesError={devicesAccessIssuesError}
          endCallModal={endCallModal}
          openEndCallModal={openEndCallModal}
          showMainVideoMessageSpinner={showMainVideoMessageSpinner}
          mainVideoMessage={mainVideoMessage}
          controlsExitLabel={controlsExitLabel}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  background-color: #${N1000};
`
