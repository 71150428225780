import React from "react"
import styled from "@emotion/styled"
import { fontSize, Typography } from "@intouchhealth/ui-components/typography"
import { indent } from "@intouchhealth/ui-components/theme"
import { AccessDeniedImage } from "components/Illustrations"
import { SmallMobile } from "components/theme"
import { BodyText, TitleText } from "components/typography"
import { HyperlinkAnchor } from "components/Hyperlink"

type Props = {
  title: string
  body1: string
  body2?: string
  support?: string
}

export const AuthError = ({ title, body1, body2, support }: Props) => {
  return (
    <MainContainer>
      <AccessDeniedImage />
      <TitleText>{title}</TitleText>
      <StyledBodyText>
        <div>{body1}</div>
        {body2 && <div>{body2}</div>}
      </StyledBodyText>
      {support && (
        <>
          <SupportDescription type="h3" fontColor="N500">
            {support}
          </SupportDescription>
          <StyledHyperlink href="tel:placeholder" type="h2">
            Phone Support Placeholder
          </StyledHyperlink>
        </>
      )}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledBodyText = styled(BodyText)`
  margin-bottom: ${indent(8)};
  ${SmallMobile} {
    margin-bottom: ${indent(5)};
  }
`

// TODO: FNF-147 - Show and update support details when contact information is confirmed
const SupportDescription = styled(Typography)`
  display: none;
  text-align: center;
  ${SmallMobile} {
    font-size: ${fontSize().h4.fontSize};
  }
`

const StyledHyperlink = styled(HyperlinkAnchor)`
  display: none;
  text-align: center;
  ${SmallMobile} {
    font-size: ${fontSize().h3.fontSize};
  }
`
