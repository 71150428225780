import React from "react"
import styled from "@emotion/styled"
import { translation } from "locales"
import { indent, Mobile } from "@intouchhealth/ui-components/theme"
import { MomChildImage } from "components/Illustrations"
import { TitleText } from "components/typography"
import { LargePositiveButton } from "components/LargePositiveButton"

type Props = {
  onRejoin: () => void
}

export const LeftCall = (props: Props) => {
  const { onRejoin } = props
  return (
    <MainContainer>
      <MomChildImage />
      <TitleText>{translation.guest.leftCall.title}</TitleText>
      <LeftCallButton
        dataTarget="rejoin-call"
        onClick={onRejoin}
        buttonText={translation.buttonLabels.rejoinCall}
      />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${Mobile} {
    padding-top: ${indent(3)};
  }
`

const LeftCallButton = styled(LargePositiveButton)`
  margin-top: ${indent(2)};
`
