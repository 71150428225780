// Teladoc Health Confidential.  Copyright 2022. Teladoc Health, Inc. All rights reserved.

export enum VideoProviderType {
  ACS = "acs",
  Tokbox = "tokbox",
  Teams = "teams",
}

export enum SessionAdapterType {
  ACS = "acs",
  Tokbox = "tokbox",
}

export enum MediaStreamType {
  Video = "Video",
  ScreenSharing = "ScreenSharing",
  BackgroundBlur = "BackgroundBlur",
}

/**
 * Events related to devices
 */
export enum WebRTCEvents {
  CameraChanged = "tdh_cameraChanged",
  MicrophoneChanged = "tdh_microphoneChanged",
  SpeakerChanged = "tdh_speakerChanged",
  CameraListChanged = "tdh_cameraListChanged",
  MicrophoneListChanged = "tdh_microphoneListChanged",
  SpeakerListChanged = "tdh_speakerListChanged",
}

/**
 * Events related to session adapters
 */
export enum AdapterEvents {
  StateChanged = "tdh_stateChanged",
  MutedChanged = "tdh_mutedChanged",
  ScreenShareChanged = "tdh_screenShareChanged",
  ParticipantListChanged = "tdh_participantListChanged",
  ParticipantSpeakingChanged = "tdh_participantSpeakingChanged",
  ParticipantVideoStreamChanged = "tdh_participantVideoStreamChanged",
  MediaStreamListChanged = "tdh_mediaStreamListChanged",
  RemoteParticipantMediaStreamListChanged = "tdh_remoteParticipantMediaStreamListChanged",
  RemoteParticipantMutedChanged = "tdh_remoteParticipantMutedChanged",
  RemoteParticipantStateChanged = "tdh_remoteParticipantStateChanged",
  RemoteParticipantScreenShareChanged = "tdh_remoteParticipantScreenShareChanged",
  BackgroundBlurChanged = "tdh_backgroundBlurChanged",
}

export enum ErrorMessage {
  ConnectCall = "Error while connecting to call",
  DisconnectCall = "Error while disconnecting from call",
  StartScreenShare = "Error while starting screen share",
  StopScreenShare = "Error while stopping screen share",
  MuteAudio = "Error while muting audio",
  UnmuteAudio = "Error while unmuting audio",
  StartVideo = "Error while starting video",
  StopVideo = "Error while stopping video",
  GetParticipants = "Error while getting participants",
  SubscribingEvent = "Error while subscribing to an event",
  UnsubscribingEvent = "Error while unsubscribing from an event",
  CameraUnavailable = "No Camera is available to start a video",
  CallNotInitiated = "Call is not initiated",
  AddVideo = "Error while starting additional video stream",
  RemoveVideo = "Error while removing additional video stream",
  ChangeVideoStreamSource = "Error while changing video stream source",
  UnknownStream = "Stream doesn't exist (invalid/unknown streamId)",
  GetCameras = "WebRTC Device Manager - Error while getting Cameras",
  SetCamera = "WebRTC Device Manager - Error while setting Camera",
  GetMicrophones = "WebRTC Device Manager - Error while getting Microphones",
  SetMicrophone = "WebRTC Device Manager - Error while setting Microphone",
  GetSpeakers = "WebRTC Device Manager - Error while getting Speakers",
  SetSpeaker = "WebRTC Device Manager - Error while setting Speaker",
  StreamSubscribingError = "Error while subscribing to stream",
  PublisherError = "Error while initializing publisher",
  PreCallTestError = "Error during precall test",
  LocalVideoStreamAvailable = "Local Video Stream already available",
  GetAudioStream = "WebRTC Device Manager - Error while getting audio stream",
  GetVideoStream = "WebRTC Device Manager - Error while getting video stream",
  SetSink = "WebRTC Device Manager - Error while setting a sink",
  PlayTestAudio = "WebRTC Device Manager - Error while playing test audio",
  StopTestAudio = "WebRTC Device Manager - Error while stopping test audio",
  VideoStreamAlreadyStarted = "Video Stream already available",
  VideoStreamAlreadyStopped = "Video Stream already stopped",
  EnableBackgroundBlur = "Error while enabling background blur",
  DisableBackgroundBlur = "Error while disabling background blur",
}

/**
 * Events for watchRTC data gathering
 */
export enum SessionEvents {
  ScreenShareStarted = "ScreenSharing Started",
  ScreenShareStopped = "ScreenSharing Stopped",
  AudioMuted = "Audio Muted",
  AudioUnmuted = "Audio Unmute",
  VideoStarted = "Video Started",
  VideoStopped = "Video Stopped",
  VideoStreamAdded = "Video Stream Added",
  VideoStreamRemoved = "Video Stream Removed",
  CameraChanged = "Video Camera Changed",
  AudioInputChanged = "Audio Input Device Changed",
  AudioOutputChanged = "Audio Output Device Changed",
  BackgroundBlurEnabled = "Background Blur Enabled",
  BackgroundBlurDisabled = "Background Blur Disabled",
}
