import React from "react"
import { Blue, G500, N500 } from "@intouchhealth/ui-components/colors"
import styled from "@emotion/styled"
import { indent } from "@intouchhealth/ui-components/theme"
import {
  CheckmarkIcon,
  CloseIcon as XIcon,
} from "@intouchhealth/ui-components/icons"
import { fontSize, Typography } from "@intouchhealth/ui-components/typography"
import {
  AdaptiveChromeImage,
  AdaptiveFirefoxImage,
  AdaptiveSafariImage,
  UnsupportedBrowserMobileBreakpoint,
} from "./BrowserImages"
import { isBrowserSupportedForOs } from "Init/browser"
import { translation } from "locales"

export const SupportedBrowserGrid = () => {
  return (
    <BrowserGridContainer>
      <EmptySpace />
      <BrowserImageAndNameContainer>
        <AdaptiveChromeImage />
        <AdaptiveTypography type="h4">
          {translation.unsupportedBrowser.browser.chrome}
        </AdaptiveTypography>
      </BrowserImageAndNameContainer>
      <BrowserImageAndNameContainer>
        <AdaptiveSafariImage />
        <AdaptiveTypography type="h4">
          {translation.unsupportedBrowser.browser.safari}
        </AdaptiveTypography>
      </BrowserImageAndNameContainer>
      <BrowserImageAndNameContainer>
        <AdaptiveFirefoxImage />
        <AdaptiveTypography type="h4">
          {translation.unsupportedBrowser.browser.firefox}
        </AdaptiveTypography>
      </BrowserImageAndNameContainer>
      <BrowserGridOsRow
        includeLineSeparator
        os="android"
        osDisplayName={translation.unsupportedBrowser.os.android}
      />
      <BrowserGridOsRow
        includeLineSeparator
        os="ios"
        osDisplayName={translation.unsupportedBrowser.os.ios}
      />
      <BrowserGridOsRow
        includeLineSeparator
        os="linux"
        osDisplayName={translation.unsupportedBrowser.os.linux}
      />
      <BrowserGridOsRow
        includeLineSeparator
        os="macos"
        osDisplayName={translation.unsupportedBrowser.os.macos}
      />
      <BrowserGridOsRow
        includeLineSeparator={false}
        os="windows"
        osDisplayName={translation.unsupportedBrowser.os.windows}
      />
    </BrowserGridContainer>
  )
}

const BrowserGridOsRow = ({
  os,
  osDisplayName,
  includeLineSeparator,
}: {
  os: string
  osDisplayName: string
  includeLineSeparator: boolean
}) => {
  const isSafariSupported = isBrowserSupportedForOs(os, "safari")
  const isFirefoxSupported = isBrowserSupportedForOs(os, "firefox")
  const isChromeSupported = isBrowserSupportedForOs(os, "chrome")

  return (
    <>
      <PaddedTypography type="h3">{osDisplayName}</PaddedTypography>
      <IsSupportedIcon isSupported={isChromeSupported} />
      <IsSupportedIcon isSupported={isSafariSupported} />
      <IsSupportedIcon isSupported={isFirefoxSupported} />
      {includeLineSeparator && <LineSeparator />}
    </>
  )
}

const IsSupportedIcon = ({ isSupported }: { isSupported: boolean }) => {
  return isSupported ? (
    <CenteredCheckMarkIcon fill={G500} />
  ) : (
    <CenteredXIcon fill={N500} />
  )
}

const BrowserGridContainer = styled.div`
  display: grid;
  height: 408px;
  width: 560px;
  grid-column-gap: 83px;
  grid-template-columns: 103px 60px 60px 60px;
  grid-template-rows: 110px 60px 1px 60px 1px 60px 1px 60px 1px 60px;
  ${UnsupportedBrowserMobileBreakpoint} {
    width: 312px;
    height: 363px;
    grid-column-gap: 20px;
    grid-template-columns: 58px 60px 60px 60px;
    grid-template-rows: 75px 60px 1px 60px 1px 60px 1px 60px 1px 60px;
  }
`

const AdaptiveTypography = styled(Typography)`
  color: #${Blue};
  ${UnsupportedBrowserMobileBreakpoint} {
    font-size: ${fontSize().h6.fontSize};
  }
`

const PaddedTypography = styled(Typography)`
  padding-left: ${indent(4)};
  align-self: center;
  color: #${Blue};
  flex-basis: 5%;
  ${UnsupportedBrowserMobileBreakpoint} {
    font-size: ${fontSize().h6.fontSize};
    padding-left: ${indent(2)};
  }
`

const EmptySpace = styled.div``

const BrowserImageAndNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  margin-bottom: ${indent(2)};
  ${UnsupportedBrowserMobileBreakpoint} {
    height: 75px;
    width: 40px;
    justify-self: center;
  }
`

const CenteredXIcon = styled(XIcon)`
  justify-self: center;
  align-self: center;
`

const CenteredCheckMarkIcon = styled(CheckmarkIcon)`
  justify-self: center;
  align-self: center;
`

const LineSeparator = styled.hr`
  width: 105%;
  grid-column-start: 1;
  grid-column-end: 5;
  height: 1px;
  opacity: 0.5;
  margin-top: 0px;
  margin-bottom: 0px;
`
