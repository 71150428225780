import React from "react"
import styled from "@emotion/styled"
import { translation } from "locales"
import { indent } from "@intouchhealth/ui-components/theme"
import { MomChildImage } from "components/Illustrations"
import { TitleText } from "components/typography"
import { LargePositiveButton } from "components/LargePositiveButton"

type Props = {
  onNewCall: () => void
}

export const PureEnded = (props: Props) => {
  const { onNewCall } = props
  return (
    <MainContainer>
      <MomChildImage />
      <TitleText>{translation.patient.ended.title}</TitleText>
      <BeginNewCallButton
        onClick={onNewCall}
        buttonText={translation.buttonLabels.beginNewCall}
        dataTarget="begin-new-call-button"
      />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BeginNewCallButton = styled(LargePositiveButton)`
  margin-top: ${indent(2)};
`
