import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Controls } from "./Controls"
import { DevicesModal } from "./Controls/DevicesSettingsModal"
import { useVideoCallDevices } from "@intouchhealth/videocall/devices"
import { indent } from "@intouchhealth/ui-components/theme"
import { N1000 } from "@intouchhealth/ui-components/colors"
import { AspectRatio } from "./AspectRatio"
import { VideoFromStream } from "@intouchhealth/videocall"
import { Participant } from "@intouchhealth/videocall/participants"
import { NoRemoteParticipant } from "./NoRemoteParticipant"

type Props = {
  showMainVideoMessageSpinner: boolean
  mainVideoMessage: string
  devicesAccessIssuesError: string
  endCallModal: JSX.Element
  openEndCallModal: () => void
  controlsExitLabel: string
  participants: Participant[]
  selfId?: string
}

export const InCall = ({
  showMainVideoMessageSpinner,
  mainVideoMessage,
  devicesAccessIssuesError,
  endCallModal,
  openEndCallModal,
  controlsExitLabel,
  participants,
  selfId,
}: Props) => {
  const [isDevicesModalShown, setDevicesModalOpen] = useState(false)
  useEffect(() => {
    if (devicesAccessIssuesError) setDevicesModalOpen(false)
  }, [devicesAccessIssuesError])
  const devicesProps = useVideoCallDevices()
  const selfParticipant = participants.find(
    (participant) => participant.id === selfId
  )
  const remoteParticipant = participants.find(
    (participant) => participant.id !== selfId
  )
  return (
    <MainContainer>
      {endCallModal}
      <DevicesModal
        isOpen={isDevicesModalShown}
        onClose={() => setDevicesModalOpen(false)}
        {...devicesProps}
      />
      <ThumbnailVideoArea data-target="thumbnail-video-area">
        <AspectRatio heightVH={15} heightPX={-32} minHeightPX={120}>
          <ThumbnailVideoContainer data-target="thumbnail-video-container">
            {selfParticipant?.id && selfParticipant?.cameraStream && (
              <VideoFromStream
                key={selfParticipant.id}
                stream={selfParticipant.cameraStream}
              />
            )}
          </ThumbnailVideoContainer>
        </AspectRatio>
      </ThumbnailVideoArea>
      <MainVideoArea data-target="main-video-area">
        {!remoteParticipant ? (
          <NoRemoteParticipant
            showMessageSpinner={showMainVideoMessageSpinner}
            message={mainVideoMessage}
          />
        ) : (
          <MainVideoContainer data-target="main-video-container">
            <CenteredAspectRatio
              heightVH={85}
              maxHeightVH={100}
              maxHeightPX={-152}
            >
              {remoteParticipant.cameraStream && (
                <VideoFromStream
                  key={remoteParticipant.id}
                  stream={remoteParticipant.cameraStream}
                />
              )}
            </CenteredAspectRatio>
          </MainVideoContainer>
        )}
      </MainVideoArea>

      <StyledControls
        isDevicesSettingsModalShown={isDevicesModalShown}
        openDevicesSettingsModal={() => setDevicesModalOpen(true)}
        openEndCallModal={openEndCallModal}
        controlsExitLabel={controlsExitLabel}
      />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ThumbnailVideoArea = styled.div`
  width: 100%;
  padding: ${indent(2)} 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #000000;
`

const ThumbnailVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  & .OT_video-poster {
    background-color: #${N1000};
    background-position: center !important;
  }
`

const MainVideoArea = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
`

const MainVideoContainer = styled.div`
  height: 100%;
  width: 100%;
  & .OT_video-poster {
    background-color: #${N1000};
    background-position: center !important;
  }
`

const CenteredAspectRatio = styled(AspectRatio)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledControls = styled(Controls)`
  z-index: 1;
`
