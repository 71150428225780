import React from "react"
import styled from "@emotion/styled"
import { translation } from "locales"
import { indent, Mobile } from "@intouchhealth/ui-components/theme"
import { MomChildImage } from "components/Illustrations"
import { BodyText, TitleText } from "components/typography"
import { LargePositiveButton } from "components/LargePositiveButton"
import {
  PrivacyPolicyLink,
  TermsAndConditionsLink,
} from "components/TermsAndConditions"

type Props = {
  onStart: () => void
  onViewTermsAndConditions: () => void
  titleText: string
  bodyText?: string
  buttonText: string
}

export const PureStart = (props: Props) => {
  const { onStart, onViewTermsAndConditions, titleText, bodyText, buttonText } =
    props
  return (
    <MainContainer>
      <MomChildImage />
      <TitleText>{titleText}</TitleText>
      {bodyText && <BodyText>{translation.patient.start.body}</BodyText>}
      <StyledTermsAndConditionsLink
        onClick={onViewTermsAndConditions}
        dataTarget="terms-and-conditions-link"
      />
      <StartButton
        dataTarget="join-call-button"
        onClick={onStart}
        buttonText={buttonText}
      />
      <StyledPrivacyPolicyLink dataTarget="privacy-policy-link" />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledTermsAndConditionsLink = styled(TermsAndConditionsLink)`
  margin-top: ${indent(7)};
  margin-bottom: ${indent(4)};
  ${Mobile} {
    margin-top: ${indent(4)};
    margin-bottom: ${indent(2.5)};
  }
`

const StartButton = styled(LargePositiveButton)`
  margin-top: ${indent(2)};
`

const StyledPrivacyPolicyLink = styled(PrivacyPolicyLink)`
  margin-top: ${indent(10)};
  ${Mobile} {
    margin-top: ${indent(6)};
  }
`
