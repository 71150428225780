import { AsYouType } from "libphonenumber-js"

export const getFormattedMobileNumber = (value: string): string => {
  const hasIncompleteParens = value.includes("(") && !value.includes(")")
  return hasIncompleteParens
    ? value.replace("(", "")
    : new AsYouType("US").input(value)
}

export const isMobileNumberValid = (value: string) => {
  const asYouType = new AsYouType("US")
  asYouType.input(value)
  return !!asYouType.getNumber()?.isPossible()
}
