import React, { ReactNode } from "react"
import styled from "@emotion/styled"
import { fontSize, Typography } from "@intouchhealth/ui-components/typography"
import { indent } from "@intouchhealth/ui-components/theme"
import { SmallMobile } from "./theme"

type Props = {
  children: ReactNode
  className?: string
}

export const TitleText = (props: Props) => (
  <TitleTypography className={props.className} type="h1" fontColor="N900">
    {props.children}
  </TitleTypography>
)

export const BodyText = (props: Props) => (
  <BodyTypography className={props.className} type="h3" fontColor="N500">
    {props.children}
  </BodyTypography>
)

export const FootnoteText = (props: Props) => (
  <FootnoteTypography className={props.className} type="h5" fontColor="N500">
    {props.children}
  </FootnoteTypography>
)

const TitleTypography = styled(Typography)`
  text-align: center;
  ${SmallMobile} {
    font-size: ${fontSize().h2.fontSize};
  }
`

const BodyTypography = styled(Typography)`
  text-align: center;
  ${SmallMobile} {
    font-size: ${fontSize().h4.fontSize};
  }
  margin-top: ${indent(1)};
`

const FootnoteTypography = styled(Typography)`
  text-align: center;
  ${SmallMobile} {
    font-size: ${fontSize().h6.fontSize};
  }
`
