import { Button } from "@intouchhealth/ui-components/button"
import { CheckmarkIcon, LinkIcon } from "@intouchhealth/ui-components/icons"
import { translation } from "locales"
import React, { useEffect, useState } from "react"

type Props = {
  link: string
}

export const CopyLinkButton = (props: Props) => {
  const { link } = props
  const [showLinkCopied, setShowLinkCopied] = useState(false)
  const [linkCopiedTimeout, setLinkCopiedTimeout] = useState(0)

  const copyLink = () => {
    clearTimeout(linkCopiedTimeout)
    void navigator.clipboard.writeText(link)
    setShowLinkCopied(true)
    setLinkCopiedTimeout(
      window.setTimeout(() => {
        setShowLinkCopied(false)
      }, 3000)
    )
  }

  useEffect(() => () => window.clearTimeout(linkCopiedTimeout), [])

  return showLinkCopied ? (
    <Button icon={<CheckmarkIcon />} appearance="filled" onClick={copyLink}>
      {translation.buttonLabels.copied}
    </Button>
  ) : (
    <Button icon={<LinkIcon />} appearance="outline" onClick={copyLink}>
      {translation.buttonLabels.copyInvite}
    </Button>
  )
}
