import React, { ReactNode, useEffect, useMemo } from "react"
import { useHistory, useLocation } from "react-router"
import { createEvent, createStore, Event } from "effector"
import { useStore } from "effector-react"

const setBlockedNavigation = createEvent()
const clearBlockedNavigation = createEvent()

const blockedNavigationStore = createStore(false)
  .on(setBlockedNavigation, () => true)
  .on(clearBlockedNavigation, () => false)

type Props = {
  children: (blocked: Event<void>) => ReactNode
}

export const BlockNavigation = (props: Props) => {
  const history = useHistory()
  const location = useLocation()
  const blockedNavigation = useStore(blockedNavigationStore)
  const navigationBlocked = useMemo(() => createEvent(), [])

  useEffect(() => {
    if (blockedNavigation) {
      navigationBlocked()
      clearBlockedNavigation()
    }

    return () => {
      setBlockedNavigation()
      history.push(location.pathname)
    }
  }, [])

  return <>{props.children(navigationBlocked)}</>
}
