import React from "react"
import styled from "@emotion/styled"
import { Typography } from "@intouchhealth/ui-components/typography"
import {
  CheckmarkIcon,
  CloseIcon as XIcon,
} from "@intouchhealth/ui-components/icons"
import { Blue, G500, N500 } from "@intouchhealth/ui-components/colors"
import { translation } from "locales"
import { BrowserName, isBrowserSupportedOnCurrentOs } from "Init/browser"
import {
  FadedAdaptiveChromeImage,
  FadedAdaptiveFirefoxImage,
  FadedAdaptiveSafariImage,
  AdaptiveChromeImage,
  AdaptiveFirefoxImage,
  AdaptiveSafariImage,
  UnsupportedBrowserMobileBreakpoint,
} from "./BrowserImages"

export const CurrentOsSupportedBrowsers = () => {
  return (
    <BrowserInfoContainer>
      <BrowserInfo
        browserName="chrome"
        browserDisplayName={translation.unsupportedBrowser.browser.chrome}
      />
      <BrowserInfo
        browserName="safari"
        browserDisplayName={translation.unsupportedBrowser.browser.safari}
      />
      <BrowserInfo
        browserName="firefox"
        browserDisplayName={translation.unsupportedBrowser.browser.firefox}
      />
    </BrowserInfoContainer>
  )
}

const BrowserInfo = ({
  browserName,
  browserDisplayName,
}: {
  browserName: BrowserName
  browserDisplayName: string
}) => {
  const isSupported = isBrowserSupportedOnCurrentOs(browserName)
  return (
    <SupportedBrowserInfo>
      <BrowserImage browserName={browserName} isSupported={isSupported} />
      <SupportedTypography isSupported={isSupported} type="h4">
        {browserDisplayName}
      </SupportedTypography>
      {isSupported ? <CheckmarkIcon fill={G500} /> : <XIcon fill={N500} />}
    </SupportedBrowserInfo>
  )
}

const BrowserImage = ({
  browserName,
  isSupported,
}: {
  browserName: BrowserName
  isSupported: boolean
}) => {
  switch (browserName) {
    case "safari":
      return isSupported ? (
        <AdaptiveSafariImage />
      ) : (
        <FadedAdaptiveSafariImage />
      )
    case "chrome":
      return isSupported ? (
        <AdaptiveChromeImage />
      ) : (
        <FadedAdaptiveChromeImage />
      )
    case "firefox":
      return isSupported ? (
        <AdaptiveFirefoxImage />
      ) : (
        <FadedAdaptiveFirefoxImage />
      )
  }
}

const BrowserInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 321px;
  height: 136px;
  ${UnsupportedBrowserMobileBreakpoint} {
    width: 250px;
  }
`

const SupportedBrowserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  height: 136px;
`

const SupportedTypography = styled(Typography)`
  ${({ isSupported }: { isSupported: boolean }) =>
    `color: #${isSupported ? Blue : N500};`}
`
