import { getProxyUrl } from "./Proxy";
import {
  getTurnServer,
  TurnCredentials,
  TurnServer,
  GetTurnServerOptions,
  createTurnUrlList,
  TURN_TIMEOUT_MS,
  UDP_GRACE_PERIOD_MS
} from "./Turn";

export type TransportPolicy = "relay" | "all";

export type IceConfigOptions = {
  transportPolicy: TransportPolicy;
  allowTcpTurnServers: boolean;
  useSingleTurnServer: boolean;
};

type IceConfig = {
  includeServers: "all" | "custom";
  transportPolicy: TransportPolicy;
  customServers: TurnServer[];
};

export type SessionOptions = {
  ipWhitelist?: boolean;
  iceConfig?: IceConfig;
};

export type LegacySessionOptions = {
  proxyUrl?: string;
} & SessionOptions;

export class SessionOptionsService {
  // iceConfig
  private turnDomain?: string;
  private getTurnCredentials?: () => Promise<TurnCredentials>;
  private iceConfigOptions?: IceConfigOptions;

  enableIceConfig(
    turnDomain: string,
    getTurnCredentials: () => Promise<TurnCredentials>,
    options: IceConfigOptions
  ) {
    this.turnDomain = turnDomain;
    this.getTurnCredentials = getTurnCredentials;
    this.iceConfigOptions = options;
  }

  async getSessionOptions() {
    const sessionOptions: SessionOptions = {
      ipWhitelist: true
    };

    if (this.turnDomain && this.getTurnCredentials && this.iceConfigOptions) {
      const iceConfig = await this.getIceConfig(
        this.turnDomain,
        this.getTurnCredentials,
        this.iceConfigOptions
      );
      if (iceConfig) {
        sessionOptions.iceConfig = iceConfig;
      }
    }

    return sessionOptions;
  }

  async getLegacySessionOptions(proxyDomain?: string) {
    const sessionOptions = await this.getSessionOptions();
    const legacySessionOptions: LegacySessionOptions = { proxyUrl: getProxyUrl(proxyDomain), ...sessionOptions };
    return legacySessionOptions;
  }

  private async getIceConfig(
    turnDomain: string,
    getTurnCredentials: () => Promise<TurnCredentials>,
    iceConfigOptions: IceConfigOptions
  ) {
    const turnCredentials = await getTurnCredentials().catch(() => null);
    if (!turnCredentials) return null;

    const { username, credential } = turnCredentials;
    if (!username || !credential) return null;

    const {
      transportPolicy,
      allowTcpTurnServers,
      useSingleTurnServer
    } = iceConfigOptions;

    const udpUrls = createTurnUrlList(turnDomain, "udp");
    const tcpUrls = allowTcpTurnServers ? createTurnUrlList(turnDomain, "tcp") : [];
    const getTurnServerOptions: GetTurnServerOptions = {
      useSingleTurnServer,
      turnTimeout: TURN_TIMEOUT_MS,
      udpGracePeriod: UDP_GRACE_PERIOD_MS
    };

    const turnServer = await getTurnServer(
      udpUrls,
      tcpUrls,
      turnCredentials,
      getTurnServerOptions
    );

    const iceConfig: IceConfig = {
      includeServers: "custom",
      transportPolicy,
      customServers: [turnServer]
    };

    return iceConfig;
  }
}
