import React from "react"
import { translation } from "locales"
import { PureStart } from "components/Start"
import { getAccessCodeFromStorage } from "Guest/Invitation"
import { http } from "Guest/Http"
import { AcceptResponse } from "Guest/Invitation/Model"
import { invitationAccepted } from "Guest/Invitation/store"
import { setTokenInStorage } from "Guest/Invitation/LocalStorage"
import { HttpError } from "@intouchhealth/web-connection/Http/HttpError"
import { GoneError, NotFoundError } from "Http"

type Props = {
  onViewTermsAndConditions: () => void
  onContinue: (videocallId: string) => void
  onCodeAlreadyUsed: () => void
  onInvalidCode: () => void
}

export const Start = (props: Props) => {
  const {
    onViewTermsAndConditions,
    onContinue,
    onCodeAlreadyUsed,
    onInvalidCode,
  } = props

  const checkAccessCodeAndContinue = () => {
    const accessCode = getAccessCodeFromStorage()
    if (!accessCode) {
      onInvalidCode()
      return
    }
    http
      .get<AcceptResponse>(`/invitations/accept/${accessCode}`)
      .then((response) => {
        const { videocallId, token } = response
        invitationAccepted(response)
        setTokenInStorage(token)
        onContinue(videocallId)
      })
      .catch((e) => {
        if (e instanceof HttpError) {
          switch (e.statusCode) {
            case NotFoundError: {
              onInvalidCode()
              return
            }
            case GoneError: {
              onCodeAlreadyUsed()
              return
            }
          }
        }
        onInvalidCode()
      })
  }

  return (
    <PureStart
      onStart={checkAccessCodeAndContinue}
      onViewTermsAndConditions={onViewTermsAndConditions}
      titleText={translation.guest.start.title}
      buttonText={translation.buttonLabels.joinCall}
    />
  )
}
