import React from "react"
import { PureReview } from "./PureReview"
import { useStore } from "effector-react"
import { inviteDetailsStore } from "Patient/Invite/store"
import { nameStore } from "Patient/EnterName/store"
import { InvitationBody, InvitationResponse } from "./Model"
import { http } from "Patient/Http"
import { invitesSent } from "./store"

type Props = {
  onEditPatient: () => void
  onEditFnf: () => void
  onContinue: (videocallId: string) => void
}

export const Review = ({ onEditPatient, onEditFnf, onContinue }: Props) => {
  const patientName = useStore(nameStore)
  const inviteDetails = useStore(inviteDetailsStore)

  const sendInvitesAndContinue = () => {
    const body: InvitationBody = {
      caller: {
        name: patientName,
      },
      invitees: [
        {
          ...inviteDetails,
        },
      ],
    }

    void http
      .post<InvitationResponse>("/invitations/send", body)
      .then(({ videocallId }) => {
        invitesSent()
        onContinue(videocallId)
      })
      .catch(() => {
        // TODO: Handle the case where the send request failed.
      })
  }

  return (
    <PureReview
      patientName={patientName}
      inviteDetails={inviteDetails}
      onEditPatient={onEditPatient}
      onEditFnf={onEditFnf}
      onContinue={sendInvitesAndContinue}
    />
  )
}
